import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './style.css'
import firebase from './../../services/firebase';
import Loader from '../loader';

export default class VideoItem extends Component {

    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.state = {
            height: [],
            width: [],
            videoLength: 0
        }
    }

    componentDidUpdate = (prevProps) => {
        if (this.props != prevProps) {
            this.listenToScroll()
        }
    }

    componentDidMount() {
        window.addEventListener('mousemove', this.listenToScroll)
        window.addEventListener("scroll", this.listenToScroll)
        window.addEventListener('resize', this.listenToScroll)
    }

    componentWillUnmount() {
        window.removeEventListener('mousemove', this.listenToScroll)
        window.removeEventListener('resize', this.listenToScroll)
        window.removeEventListener("scroll", this.listenToScroll)
    }

    listenToScroll = (e) => {
        const { videoList } = this.props;
        let height = []
        let width = []
        videoList.map((item, index) => {
            const divScroll = document.getElementById("video" + index).getBoundingClientRect()
            height[index] = divScroll.height
            if (width[0]) {
                width[index] = (divScroll.width == width[0] || ((divScroll.width / 2) == width[0])) ? divScroll.width : width[0]
            } else {
                width[index] = divScroll.width
            }
        })
        // console.log(height, width)
        this.setState({ height, width })
    }

    smoothScroll = (event, item) => {
        firebase.analytics().logEvent("Work Video Clicked")
        if (item) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        } else {
            event.preventDefault();
        }
    }

    renderList = (height, width) => {
        const { videoList } = this.props;
        const { hideHover } = this.state;
        return videoList.map((item, index) => {
            let fullLength = videoList.length - 1 == index && index % 2 == 0 ? 12 : 6
            let loaderHeight = videoList.length >= 2 ? "33.34vw" : "66.68vw"
            return <Col md={fullLength} key={index} className="no-padding">
                <Link to={item.navigate ? item.navigate : ""} onClick={(event) => this.smoothScroll(event, item.navigate)}>
                    <div className="video--item" style={{ height: height[index], width: width[index] }}>
                        <a href="#" onClick={() => { }}></a>
                    </div>
                    <div className="video--content" id={"video" + index} style={{ display: 'block', position: 'relative' }}>
                        <div style={{ position: 'absolute', top: 0, left: 0, zIndex: -1, height: '100%' }}>
                            <Loader length={videoList.length} />
                        </div>
                        <video width={"100%"} id={"videoElement" + index} controls={false} src={item.link} type="video/mp4" key={index} ref={this.ref} playsInline={true} loop={true} autoPlay="autoPlay" muted={true} style={{ display: "block", minHeight: loaderHeight, backgroundColor: '#000' }} />
                        <div className="video--text">
                            <h4 className={"clother--bold " + (item.theme == "light" ? "color--white" : "color--black")}>{item.title}</h4>
                            <h4 className={"font-size__1 clother--regular " + (item.theme == "light" ? "color--white" : "color--black")}>{item.subTitle}</h4>
                        </div>
                    </div>
                </Link>
            </Col>
        })
    }

    render() {
        const { height, width } = this.state;
        return (
            <div>
                <Container fluid>
                    <Row>
                        {this.renderList(height, width)}
                    </Row>
                </Container>
            </div>
        )
    }

}



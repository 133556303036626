import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Carousel from 'react-bootstrap/Carousel'
import "./styles.css"
import arrow from "../../assets/icons/Arrow.svg"
import VideoItem from '../../components/videoItem/index'
import Header from './../../components/header'
import Footer from '../../components/footer'
import firebase from './../../services/firebase'
import ListVideo from '../../services/videoList'
import arrowbutton from "../../assets/images/Button_arrow.png"
import CommonButton from '../../components/commonbutton/commonbutton'


export default class Home extends Component {

    state = {
        videoList: ListVideo,
        carouselIndex: 1,
        totalCarousel: 3,
        currentHeight: "110vh",
        isMobile: false,
        isTab: false,
        isTabPro: false,
        isLaptop: false,
        isLandscape: false
    }

    componentDidMount = async () => {
        firebase.analytics().logEvent("Home Page Viewed")
        this.checkDevice()
    }

    checkDevice = () => {
        let isMobile = window.matchMedia("(max-width: 600px)").matches || window.matchMedia("(min-width: 600px)").matches
        let isTab = window.matchMedia("(min-width: 768px)").matches
        let isTabPro = window.matchMedia("(min-width: 1024px)").matches
        let isLaptop = window.matchMedia("(min-width: 1200px)").matches
        let isLandscape = window.matchMedia("(min-width: 600px)").matches
        this.setState({ isMobile, isTab, isTabPro, isLaptop, isLandscape })
        this.updateHeight(1)
    }

    updateHeight = (newIndex) => {
        const { icons, isMobile, isTab, isTabPro, isLaptop, isLandscape } = this.state;
        let heights = ["250vh", "95vh", "60vh"];
        if (isTabPro) {
            heights = ["250vw", "53vw", "110vw"];
        } else if (isTab) {
            heights = ["80vw", "60vw", "40vw"];
        } else if (isLandscape) {
            heights = ["200vw", "200vw", "200vw"]
        }
        else if (isMobile) {
            heights = ["200vw", "170vw", "170vw"];
        }
        let next = heights[newIndex - 1]
        console.log("next", next);
        this.setState({ currentHeight: next })
    }

    carouselClick = () => {
        const { carouselIndex, totalCarousel } = this.state;
        const newIndex = carouselIndex + 1 > totalCarousel ? 1 : carouselIndex + 1
        this.setState({ carouselIndex: newIndex })
        console.log(newIndex);
        this.updateHeight(newIndex)
    }

    handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    render() {
        const { videoList, currentHeight } = this.state;
        return (
            <div className="width--100 ">
                <Header />
                <Container fluid className="backgroundColor--black color--white">
                    <Row>
                        <Col className="no-padding backgroundColor--black">
                            <div className='Home--content'>
                                <p className="font-family--clotherRegular Home--title font-size__700" style={{lineHeight:1.2}}>Distinctive brands for passionate people</p>
                                <CommonButton onClick={this.handleClick} value={{ bcolor: "white", arrow: "white",text:"Contact us",margin:"8%",link:"/contact" }} />
                            </div>
                        </Col>
                    </Row>
                </Container>

                <VideoItem videoList={videoList} />

                <Container fluid className="no-padding backgroundColor--white">
                    <Carousel id="carousel" onSlide={this.carouselClick} style={{ maxHeight: `${currentHeight}` }} className="Home--feedback" nextIcon={<img src={arrow} alt="arrow" className="arrow__right"></img>}
                        interval={null}>
                        <Carousel.Item className="backgroundColor--white color--black" >
                            <Carousel.Caption className="carousel-padding" >

                                <Row className="paddingRight">
                                    <Col className="no-padding"><p className="font-family--clotherRegular p--paddingBottom-4  font-size__51 line-height__half Home--Feedback-description color--black"><i>" </i>Our peer
                                        reviews for our brand have been met with great appreciation and success. Overall we were very inspired and excited the artful approach they took to creating our brand and we feel
                                        it is truly world class.<i>"</i></p></Col>
                                </Row>
                                <Row className="line-height__half paddingRight">
                                    <Col className="no-padding">
                                        <p className="font-family--clotherRegular font-size__31 Home--Feedback-name color--black">Kelly Deck </p>
                                        <p className="font-family--notoSerif font-size__17 Home--Feedback-client color--black">Founder and Owner, Kelly Deck Design</p>
                                    </Col>
                                </Row>


                            </Carousel.Caption>
                        </Carousel.Item>

                        <Carousel.Item className="backgroundColor--white color--black" >
                            <Carousel.Caption className="carousel-padding">

                                <Row className="paddingRight">
                                    <Col className="no-padding"><p className="font-family--clotherRegular p--paddingBottom-4  font-size__51 line-height__half Home--Feedback-description color--black"><i>" </i>
                                        Tardigrada has a rare combination of design savvy, haptic intelligence and global influences. Their work is outstanding, their ability to work to tight timelines impressive,
                                        and their commitment to conceptual design is rare. They are a new gem in Vancouver's design landscape.<i>"</i></p></Col>
                                </Row>
                                <Row className="line-height__half paddingRight">
                                    <Col className="no-padding">
                                        <p className="font-family--clotherRegular font-size__31 Home--Feedback-name color--black">Sirish Rao</p>
                                        <p className="font-family--notoSerif font-size__17 Home--Feedback-client color--black">Director of public engagement and learning, Vancouver Art Gallery</p>
                                    </Col>
                                </Row>
                            </Carousel.Caption>
                        </Carousel.Item>

                        <Carousel.Item className="backgroundColor--white color--black" >
                            <Carousel.Caption className="carousel-padding">

                                <Row className="paddingRight">
                                    <Col className="no-padding"><p className="font-family--clotherRegular p--paddingBottom-4  font-size__51 line-height__half Home--Feedback-description color--black">
                                        <i>"</i> I<i>'</i> ve worked with Maria and Shan for several years on public health campaigns and their work is top notch. Tardigrada Design Studio has raised the
                                        quality and level of engagement. Overall the project has been very successful; they’re the first people we call when we outsource work.<i>"</i></p></Col>
                                </Row>
                                <Row className="line-height__half paddingRight">
                                    <Col className="no-padding">
                                        <p className="font-family--clotherRegular font-size__31 Home--Feedback-name color--black">Patrick Higgins</p>
                                        <p className="font-family--notoSerif font-size__17 Home--Feedback-client color--black">Communicators officer, Doctors of BC</p>
                                    </Col>
                                </Row>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item className="backgroundColor--white color--black" >
                            <Carousel.Caption className="carousel-padding" >

                                <Row className="paddingRight">
                                    <Col className="no-padding"><p className="font-family--clotherRegular p--paddingBottom-4  font-size__51 line-height__half Home--Feedback-description color--black"><i>" </i>Just had a spectacular experience with Tardigrada Design Studio... if you're looking for someone, hands down this new startup team just delivered brilliance. This was delivered on a crazy deadline with amazing quality. Couldn’t be more impressed.<i>"</i></p></Col>
                                </Row>
                                <Row className="line-height__half paddingRight">
                                    <Col className="no-padding">
                                        <p className="font-family--clotherRegular font-size__31 Home--Feedback-name color--black">Sandy Garossino</p>
                                        <p className="font-family--notoSerif font-size__17 Home--Feedback-client color--black">Writer and former trial lawyer</p>
                                    </Col>
                                </Row>


                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>

                </Container>

                <Container fluid className="no-padding backgroundColor--white widget--container" >
                    <div className="flex--row__spaceevenly">
                        <div className='widget--box'>
                            <div class="clutch-widget" data-url="https://widget.clutch.co" data-widget-type="2" data-height="45" data-nofollow="true" data-expandifr="true" data-scale="100" data-clutchcompany-id="2069180"></div>
                        </div>
                    </div>
                </Container>

                <Footer />
            </div >
        )
    }
}

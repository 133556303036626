import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Logo from './logo/index'
import Menu from './menu/index'

export default class Header extends Component {
    render() {
        const {theme} = this.props
        return (
            <Container className="backgroundColor--black no-margin" fluid>
                <Row style={{ alignContent:'center', alignItems:'center'}}>
                    <Col>
                        <Logo theme={theme?theme:"light"}/>
                    </Col>
                    <Col>
                        <Menu/>
                    </Col>
                </Row>
            </Container>
        )
    }
}

import React, { Component } from 'react'
import { Grid } from "@material-ui/core";
import { Col, Container, Image, Row } from "react-bootstrap";
import SquareButton from './SquareButton.js';
import seeAll from './../../assets/images/Buttons/sellallvideos.svg';
import baedimg from './../../assets/images/Buttons/baed.svg';
import Navigator from './navigator.js';
import Based from './based';
import './style.css';
import Header from '../header';
import Footer from '../footer';
import firebase from '../../services/firebase';
import RandomVideos from '../randomVideos.js/index.js';
import box from "../../assets/images/Spade _ Plate/Icon_Inprocess.png"
import { Link } from 'react-router-dom';
import CommonButton from '../commonbutton/commonbutton.js';
let cover = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FBAED%2F01_Image.png?alt=media')
let image_2 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FBAED%2F02_Image.png?alt=media')
let image_3 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FBAED%2F03_Image.gif?alt=media')
let image_4 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FBAED%2F04_Image.png?alt=media')
let image_6 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FBAED%2F06_Image.png?alt=media')
let image_7 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FBAED%2F07_Image.gif?alt=media')
let image_8 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FBAED%2F08_Image.jpg?alt=media')
let image_9 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FBAED%2F09_Image.png?alt=media')
let image_10 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FBAED%2F010_Image.png?alt=media')
let image_11 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FBAED%2F011_Image.png?alt=media')
let image_12 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FBAED%2F012_Image.png?alt=media')
let image_13 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FBAED%2F013_Image.png?alt=media')
let image_14 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FBAED%2F014_Image.png?alt=media')
let image_15 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FBAED%2F015_Image.png?alt=media')
let image_16 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FBAED%2F015_Image.gif?alt=media')
let banner = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FBAED%2F05_Banner.mp4?alt=media');
let spade = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/videos%2Fspade%26plate%2FAnimation%20spade%26plate_Cover.mp4?alt=media&token=38d2aa16-cf85-4a8e-9e16-8269e1ca1eb8');


export default class SPADEPLATE extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drawerState: "null"
        };
    }

    componentDidMount() {
        var url_string = window.location.href
        var url = new URL(url_string);
        var filter = url.searchParams.get("filter");
        firebase.analytics().logEvent("BAED Page Viewed")
        if (filter) {
            let refactor = filter.split("%20").join(" ")
            this.setState({ currentFilter: refactor });
        }
    }

    toggleDrawer = () => {
        // const { drawerState } = this.state;
        // this.setState({
        //   drawerState:
        //     drawerState == "null" || drawerState == "hide--drawer"
        //       ? "show--drawer"
        //       : "hide--drawer",
        // });
    };

    handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    render() {
        const { drawerState } = this.state;
        return (
            <div className="Template2--template2 Template backgroundColor--black">
                <Header />
                <div className='cover--image'>
                    <video playsInline preload="auto" src={spade} width="100%" loop={true} autoPlay={true} muted={true} type="video/mp4" />
                </div>
                <p className="hero--Template1 color--white clother--regular font-size__110" style={{ marginBottom: '3%', marginTop: '10%', marginLeft: '6%' }}>Spade & Plate</p>
                <Grid container style={{ marginBottom: '5%' }}>
                    <Grid xs={5} style={{ marginRight: '7%' }}>
                        <p className="color--white clother--regular font-size__30" style={{ margin: '5% 5% 20% 7vw', lineHeight: 1.6 }}>Visual identity<br /> Illustration<br /> Packaging <br /> Print Collateral<br /> Web & Mobile<br /> Social Media Graphics</p>
                    </Grid>
                    <Grid xs={6}>
                        <p className="color--white font-size__16 line-height__20 clother--regular" style={{ padding: '5% 27% 0% 7%' }}>
                            A gardening-lifestyle brand founded by Kelly Deck, one of Canada’s top interior designers. Spade and Plate provides high quality provisions for garden and dinner table.<br /><br /> The design is guided by the brand’s intention of championing slow-living and the hope that the act of gardening awakens the desire in people to become better stewards of our own environment.<br /><br /> <a style={{all:"unset", cursor:'pointer', color:"#fff"}} className="color--white" onClick={()=>window.open("https://www.spadeandplate.com")}>www.spadeandplate.com</a>
                        </p>
                    </Grid>
                </Grid>
                <Grid container style={{ backgroundColor: `rgb(255,255,255)` }} className="padding--5">
                    <Grid xs={12} style={{ textAlign: 'center' }}>
                        <div className='spade--flex--box' style={{textAlign:'center'}}>
                            <img src={box} alt="box" className='box--image' />
                            <p className='no--mobile color--black clother--regular font-size__45'> More content upon the brand’s launch</p>
                            <p className='mobile--view color--black clother--regular font-size__30' style={{marginTop:30}}> More content upon the brand’s launch</p>
                        </div>
                    </Grid>
                </Grid>

                {/* <Navigator screen={2} prev="coc" next="arris"/> */}
                <RandomVideos exclude={1}/>
                <Container fluid className="backgroundColor--black color--white">
                    <Row className="padding--5">
                        <Col className="see--all">
                            <div onClick={() => this.handleClick()}>
                                <CommonButton value={{ bcolor: "white", arrow: "white", text: "More Work", margin: "0%", link: "/work" }} />
                            </div>
                        </Col>
                    </Row>
                </Container>
                {/* <Grid container>
                    <Grid item xs={12}>
                        <Based/>
                    </Grid>
                </Grid> */}
                <Footer />
            </div>
        )
    }
}

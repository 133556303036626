import React, { Component } from 'react'
import based from './../../assets/images/Based.png';
import { Image } from "react-bootstrap";
import arrowUp from './../../assets/images/Arrow-up.png';
import './style.css';

export default class Based extends Component {

    onArrowClick=()=>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    render() {
        return (
            <div>
                {
                    this.props.from!="studio"&&
                    <Image src={arrowUp} className="pc-view sticky--arrow" onClick={this.onArrowClick} width="4%" style={{position:'fixed', bottom:"400px", right:"100px"}}/>
                }
                <p className="medium--text__white full-width"  style={{textAlign:'right', paddingRight:'5%', marginTop:'10%', fontSize:25}}> <Image src={based} width="2%" className="locate--icon" style={{marginBottom:"3.5%"}}/> Based in Vancouver, working worldwide.</p>
            </div>
        )
    }
}

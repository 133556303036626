import React, { Component } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import Footer from '../../components/footer'
import Header from '../../components/header'
import Maria from './../../assets/images/Studio/Maria.svg'
import Shan from './../../assets/images/Studio/Shan.svg'
import Reggae from './../../assets/images/Studio/Reggae.svg'
import './style.css';
import firebase from './../../services/firebase'
import processing from "../../assets/images/Team/Process.png"
import processingmobile from "../../assets/images/Team/Process_Mobile.png"
import maria from "../../assets/images/Team/MariaSinisterraj_Tgda.png"
import shan from "../../assets/images/Team/ShanSelvaraj_Tgda.png"
import team from "../../assets/images/Team/Team_Tardigradadesignstudio.png"
import rgd from "../../assets/images/_Certified-RGD-Member-Logos/Certified-RGD-Black.png"
import { Grid } from '@material-ui/core'
import CommonButton from '../../components/commonbutton/commonbutton'
import SBBC from "../../assets/images/Home/SBBC-Awards-PPC-Finalist-Badge.png"

export default class Studio extends Component {

    state = {
        icons: [
            {
                "id": "maria",
                "name": "Maria Sinisterra",
                "role": "Co-founder and designer",
                "description1": "Earned a Bachelor in Graphic design from the Universidad del Valle in Colombia and a Master of Arts in Graphic design and Visual experience from the Savannah College of Art and design.",
                "description2": '“There is no set rule or template. One of the beauties of what we do is we have structure but not rigidity.”',
                "description3": '— Peer Insight',
                "image": Maria,
                "iconWidth": 0,
                "left": 0,
                "top": 0
            },
            {
                "id": "shan",
                "name": "Shan Selvaraj",
                "role": "Co-founder and designer",
                "description1": "Earned a Bachelor of Architecture from MEASI Academy in India and a Master of Fine Arts in Interior design from the Savannah College of Art and design.",
                "description2": '“You cannot understand good design if you do not understand people; design is made for people.”',
                "description3": '— Dieter Rams',
                "image": Shan,
                "iconWidth": 0,
                "left": 0,
                "top": 0
            },
            {
                "id": "reggae",
                "name": "Reggae",
                "role": "Barker",
                "description1": "Earned a lot of wisdom, toys and treats from the University of Life.",
                "description2": '“Standing on a street corner waiting for no one is power. ”',
                "description3": '— Gregory Corso',
                "image": Reggae,
                "iconWidth": 0,
                "left": 0,
                "top": 0
            }

        ],
        list: [
            {
                id: "1",
                title: "1. Engage",
                description: "We connect with potential clients and define the scope of work."
            },
            {
                id: "3",
                title: "3. Ideate",
                description: "We vigorously develop concepts based on our findings."
            },
            {
                id: "5",
                title: "5. Develop",
                description: "We transform ideas into strategies and solutions."
            },
            {
                id: "2",
                title: "2. Discover",
                description: "We immerse ourselves deep into the client’s company and industry."
            },  {
                id: "4",
                title: "4. Evaluate",
                description: "In collaboration with our clients, we identify the strongest concept."
            }, 
            {
                id: "6",
                title: "6. Deliver",
                description: "We equip our clients with all they need for the conquest and their journey."
            },
        ],
        listMobile: [
            {
                id: "1",
                title: "1. Engage",
                description: "We connect with potential clients and define the scope of work."
            },
            {
                id: "2",
                title: "2. Discover",
                description: "We immerse ourselves deep into the client’s company and industry."
            },
            {
                id: "3",
                title: "3. Ideate",
                description: "We vigorously develop concepts based on our findings."
            },
            {
                id: "4",
                title: "4. Evaluate",
                description: "In collaboration with our clients, we identify the strongest concept."
            }, 
            {
                id: "5",
                title: "5. Develop",
                description: "We transform ideas into strategies and solutions."
            },
            {
                id: "6",
                title: "6. Deliver",
                description: "We equip our clients with all they need for the conquest and their journey."
            },
        ],
        Description: [
            {
                desc: "Maria is inquisitive and pushes the design boundaries to produce the finest quality of work. She commits to conceptual design and has a keen eye for typography. "
            },
            {
                desc: "“There is no set rule or template. One of the beauties of what we do is we have structure but not rigidity.”",
                margin:"0rem"
            },
            {
                desc: "— Peer insight, Missing the exit "
            },
            {
                desc: "Master of Arts in Graphic design and Visual experience from the Savannah College of Art and design (SCAD), Savannah, Georgia, US. Bachelor of Graphic Design from Universidad del Valle, Cali, Colombia."
                // desc: <span>Master of Arts in Graphic design and Visual experience from the Savannah College of Art and design (SCAD), Savannah, Georgia, US. Bachelor of Graphic Design from Universidad del Valle, <br/>Cali, Colombia.</span>
            }
        ],
        Description2: [
            {
                desc: "Shan believes design is the perfect blend of art, psychology, and reason. He fosters process-driven design as a way to create unique ideas, and he combines his expertise to offer comprehensive solutions."
                // desc: <span>Shan believes design is the perfect blend of art, psychology, and reason. He fosters process-driven design as a way to create unique ideas, and he combines his expertise to offer <br/>comprehensive solutions.</span>
            },
            {
                desc: "“You cannot understand good design if you do not understand people; design is made for people.”",
                margin:"0rem"
            },
            {
                desc: "— Dieter Rams "
            },
            {
                desc: " Master of Fine Arts in Interior design from the Savannah College of Art and design (SCAD) Savannah, Georgia, US. Bachelor of Architecture from MEASI Academy, Chennai, India."
            }
        ],





        isMobile: false,
        isTab: false,
        isTabPro: false,
        isLaptop: false,
        isLandscape: false
    }

    checkDevice = () => {
        let isMobile = window.matchMedia("(max-width: 600px)").matches || window.matchMedia("(min-width: 600px)").matches
        let isTab = window.matchMedia("(min-width: 768px)").matches
        let isTabPro = window.matchMedia("(min-width: 1024px)").matches
        let isLaptop = window.matchMedia("(min-width: 1200px)").matches
        let isLandscape = window.matchMedia("(orientation:landscape)").matches
        this.setState({ isMobile, isTab, isTabPro, isLaptop, isLandscape })
        // this.listenToChange()
    }

    componentDidMount() {
        firebase.analytics().logEvent("Studio Page Viewed")
        window.addEventListener('scroll', this.listenToChange)
        window.addEventListener('resize', this.checkDevice)
        this.checkDevice()
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToChange)
        window.removeEventListener('resize', this.checkDevice)
    }

    // listenToChange=(e)=>{
    //     const {icons, isMobile, isTab, isTabPro, isLaptop, isLandscape} = this.state;
    //     icons.map((item, index)=>{
    //         const divScroll = document.getElementById(item.id).getBoundingClientRect()
    //         if(isLandscape && ( !isLaptop )) {
    //             let isTab_ = window.matchMedia("(min-height: 768px)").matches 
    //             if (isTab_) {
    //                 icons[index].left=divScroll.width/2
    //                 icons[index].top=index==0?divScroll.width/1.4:index==1?divScroll.width/1.5:divScroll.width/2
    //                 icons[index].iconWidth= index==0?divScroll.width*0.75:index==1?divScroll.width*0.6:divScroll.width*0.7
    //             } else {
    //                 icons[index].left=divScroll.width/2
    //                 icons[index].top=index==0?divScroll.height/1.9:index==1?divScroll.height/2.2:divScroll.height/2.5
    //                 icons[index].iconWidth= index==0?divScroll.width*0.75:index==1?divScroll.width*0.6:divScroll.width*0.7
    //             }
    //         } else if (isLaptop) {
    //             if(divScroll.top<1500 && divScroll.bottom>0) {
    //                 if(-(divScroll.top-600)*(index==1?0.78:index==2?1.1:0.66)<divScroll.width*0.5){
    //                     icons[index].left=-(divScroll.top-600)*(index==1?0.78:index==2?1.1:0.66)
    //                     icons[index].top=-(divScroll.top-600)*0.65
    //                 }
    //             } else if(divScroll.top>0){
    //                 icons[index].left=0 
    //                 icons[index].top=0
    //             }
    //             icons[index].iconWidth= index==0?divScroll.width*0.53:index==1?divScroll.width*0.385:divScroll.width*0.50
    //         } else if (isTabPro) {
    //             if(divScroll.top<900 && divScroll.bottom>0) {
    //                 if(-(divScroll.top-900)*(index==0?0.60:index==1?0.7:0.8)<divScroll.width*0.5){
    //                     icons[index].left=-(divScroll.top-900)*(index==0?0.60:index==1?0.7:0.8)
    //                     icons[index].top=-(divScroll.top-900)*0.9
    //                 }
    //             } else if(divScroll.top>0){
    //                 icons[index].left=0 
    //                 icons[index].top=0
    //             }
    //             icons[index].iconWidth= index==0?divScroll.width*0.7:index==1?divScroll.width*0.6:divScroll.width*0.7
    //         } else if (isTab) {
    //             if(divScroll.top<600 && divScroll.bottom>0) {
    //                 if(-(divScroll.top-600)*(index==0?0.55:index==1?0.60:0.70)<divScroll.width*0.5){
    //                     icons[index].left=-(divScroll.top-600)*(index==0?0.55:index==1?0.60:0.70)
    //                     icons[index].top=-(divScroll.top-600)*1
    //                 }
    //             } else if(divScroll.top>0){
    //                 icons[index].left=0 
    //                 icons[index].top=0
    //             }
    //             icons[index].iconWidth= index==0?divScroll.width*0.63:index==1?divScroll.width*0.50:divScroll.width*0.62
    //         } else if(isMobile) {
    //             let mutiplier
    //             if(window.innerWidth<400){
    //                 mutiplier = (window.innerWidth-60)/428
    //             } else {
    //                 mutiplier = (window.innerWidth)/428
    //             }
    //             if(divScroll.top<600 && divScroll.bottom>0) {
    //                 if(-(divScroll.top-600)*(index==0?(mutiplier*0.30):index==1?(mutiplier*0.35):(mutiplier*0.51))<divScroll.width*0.5){
    //                     icons[index].left=-(divScroll.top-600)*(index==0?(mutiplier*0.30):index==1?(mutiplier*0.35):(mutiplier*0.51))
    //                     icons[index].top=-(divScroll.top-600)
    //                 }
    //             } else if(divScroll.top>0){
    //                 icons[index].left=0 
    //                 icons[index].top=0
    //             }
    //             icons[index].iconWidth= index==0?divScroll.width*0.95:index==1?divScroll.width*0.7:divScroll.width*0.9
    //         } 
    //         this.setState({icons})
    //     })
    // }

    // renderFamily=()=>{
    //     const {icons} = this.state
    //     return icons.map((item, index)=>{
    //         return <Row className="padding--5 studio--family" id={item.id}>
    //         <img src={item.image} style={{width:item.iconWidth,marginTop:-item.iconWidth/4, left:-item.iconWidth/2, transform: `translate(${item.left}px,${item.top}px)`}} className="studio--image__animated" />
    //         <Col xs={12} md={12} xl={6}>
    //             <Col xs={8} md={10}>
    //                 <p className="clother--regular color--black font-size__120 line-height__11 no-margin">{item.name}</p>
    //             </Col>
    //             <Col xs={12} md={12}>
    //                 <p className="clother--regular studio--role color--black font-size__32 line-height__18">{item.role}</p>
    //             </Col>
    //         </Col>
    //         <Col xs={12} md={12} xl={6}>
    //             <Col xs={12} md={12} xl={10} className="studio--details">
    //                 <p className="noto--regular color--black font-size__17 line-height__18">{item.description1}</p>
    //                 <p className="clother--regular studio--role color--black font-size__32">{item.description2}</p>
    //                 <p className="clother--regular studio--role color--black font-size__32">{item.description3}</p>
    //             </Col>
    //         </Col>
    //     </Row>
    //     })
    // }
    handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    render() {
        const { list, listMobile, Description, Description2 } = this.state;
        return (
            <div>
                <Header />
                <div className="color--white studio--page" >
                    <Row className="studio--container backgroundColor--black padding--5">
                        <Col xs={12} xl={7}>
                            <p className="studio--head__text clother--regular font-size__120">Studio</p>
                            <Row>
                                <Col xs={12} md={11}>
                                    <p className="clother--regular font-size__30 line-height__18">We combine a vigorous design process with an artful approach to create unique brands</p>
                                </Col>
                            </Row>
                            {/* <Row>
                                <Col xs={12} md={10} xl={9}>
                                    <p className="noto--regular textColor--grey font-size__17 line-height__18">We are a collaborative and multi-cultural design studio based in Vancouver. We are curious, strategic thinkers and specialized designers transforming ideas into inspiring, distinctive and pragmatic results that withstand the test of time.</p>
                                </Col>
                            </Row> */}
                        </Col>
                    </Row>
                    <Row className="backgroundColor--white padding--5 color--black p--paddingBottom-17 ">
                        <p className="font-family--clotherRegular color--black font-size__51 studio--Item-title ">Our Process</p>
                        <div style={{padding:'3% 7%'}}>
                            <img src={processing} alt="processing" className='processing--img non--mobile' />
                            <img src={processingmobile} alt="processing" className='processing--img mobile' />
                        </div>
                        <div className='studio--flex'>
                            <Grid container spacing={4} className='studio--flex--1 no--mobile'>
                                {list.map((item) => {
                                    return (
                                        <Grid item xs={12} sm={4} spacing={12}>
                                            <p className='font-size__24 clother--regular '>{item.title}</p>
                                            <p className='font-size__16 clother--regular mb-4' style={{paddingRight:'5vw'}}>{item.description}</p>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                            <Grid container spacing={4} className='studio--flex--1 mobile--view'>
                                {listMobile.map((item) => {
                                    return (
                                        <Grid item xs={12} sm={4} spacing={12}>
                                            <p className='font-size__24 clother--regular '>{item.title}</p>
                                            <p className='font-size__16 clother--regular mb-4' style={{paddingRight:'5vw'}}>{item.description}</p>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </div>
                    </Row>
                    <Row className="padding--5 backgroundColor--black" style={{ paddingTop: '0', paddingBottom:'0px'}}>
                        <Col className="no--mobile padding--top__15 padding--bottom__15"><p className="color--white font-family--clotherRegular font-size__51 line-height__half Home--Feedback-description" >We are an award-winning design studio<br /> specializing in branding and visual experiences.<br /> Tgda was co-founded by Maria Sinisterra and<br /> Shan Selvaraj. The team brings over 20 years of<br /> experience and the design aesthetics from<br /> North America, Latin America and South Asia.</p></Col>
                        <Col className="mobile--view padding--top__15 padding--bottom__15"><p className="color--white font-family--clotherRegular font-size__51 line-height__half Home--Feedback-description" >We are an award-winning design studio specializing in branding and visual experiences. Tgda was co-founded by Maria Sinisterra and Shan Selvaraj. The team brings over 20 years of experience and the design aesthetics from North America, Latin America and South Asia.</p></Col>
                    </Row>
                    <Row className="backgroundColor--black" style={{ paddingTop: '0' ,paddingBottom:"10%", textAlign:'center'}}>
                        <CommonButton onClick={this.handleClick} value={{ bcolor: "white", arrow: "white",text:"Contact us",link:"/contact"  }} />
                    </Row>
                    <Grid style={{ backgroundColor: `rgb(149, 149, 149)`, padding: "0" }}>
                        <Grid item xs={12}>
                            <Image className="non--mobile" src={team} width="100%" />
                            <Image className="mobile" src={team} width="100%" />
                        </Grid>
                    </Grid>
                    
                    <Row className="backgroundColor--white padding--5 color--black  " style={{ paddingTop: "0" }}>
                        <div className='studio--flex'>
                            <div className='studio--flex--2--1'>
                                <p className="font-family--clotherRegular color--black  studio--name-title font-size__30">Maria Sinisterra <br />Designer & Art director</p>
                                {Description.map((item, ind_) => {
                                    return (
                                        <>
                                            <p className='font-size__16 clother--regular studio--name-description' style={{ 
                                                marginBottom: item.margin?item.margin:"2rem", color: "#18191A" }}>{item.desc}</p>
                                        </>
                                    )
                                })}
                                <div className='studio--sub--flex'>
                                    <div className='studio--sub--flex--1'>
                                        <a className='font-size__30 color--black clother--regular studio--twitter--title' href="https://www.linkedin.com/in/maria-sinisterra-rgd-7804b649/" style={{cursor: "pointer", textDecorationThickness: 2, textUnderlineOffset: 2}}>LinkedIn</a>
                                    </div>
                                    <div className='studio--sub--flex--2'>
                                        <a href='https://www.rgd.ca/designers/maria-sinisterra-rgd.php'><img src={rgd} className="rgd" alt="" /></a>
                                    </div>
                                </div>
                            </div>
                            <div className='studio--flex--2--2' style={{paddingTop:'10vh'}}>
                                <img src={maria} alt="processing" className='processing--img' />
                            </div>
                        </div>
                    </Row>
                    <Row className="backgroundColor--white padding--5 color--black  mobile" style={{ paddingTop: "0" }}>
                        <div className='studio--flex'>
                            <div className='studio--flex--2--1'>
                                <p className="font-family--clotherRegular color--black  studio--name-title font-size__30">Shan Selvaraj<br /> Designer & Strategist </p>
                                {Description2.map((item) => {
                                    return (
                                        <>
                                            <p className='font-size__16 clother--regular studio--name-description' style={{ marginBottom: item.margin?item.margin:"2rem", color: "#18191A" }}>{item.desc}</p>
                                        </>
                                    )
                                })}
                                <div className='studio--sub--flex'>
                                    <div className='studio--sub--flex--1'>
                                        <a className='font-size__30 color--black clother--regular studio--twitter--title' href="https://www.linkedin.com/in/shan-selvaraj-6377a78b/" style={{cursor: "pointer", textDecorationThickness: 2, textUnderlineOffset: 2}}>LinkedIn</a>
                                    </div>
                                </div>
                            </div>
                            <div className='studio--flex--2--2'>
                                <img src={shan} alt="processing" className='processing--img' />
                            </div>
                        </div>
                    </Row>
                    <Row className="backgroundColor--white padding--5 color--black non--mobile " style={{ paddingTop: "0", }}>
                        <div className='studio--shan--flex'>
                            <div className='studio--flex--3--1'>
                                <div className='row--start' style={{paddingTop:'10vh'}}>
                                    <img src={shan} alt="processing" className='processing--img--shan' />
                                </div>
                            </div>
                            <div className='studio--flex--3--2'>
                                <p className="font-family--clotherRegular color--black  studio--name-title font-size__30">Shan Selvaraj<br /> Designer & Strategist</p>
                                {Description2.map((item) => {
                                    return (
                                        <>
                                            <p className='font-size__16 clother--regular studio--shan-description ' style={{ marginBottom: item.margin?item.margin:"2rem", color: "#18191A" }}>{item.desc}</p>
                                        </>
                                    )
                                })}
                                <div className='studio--flex'>
                                    <div className='studio--sub--flex--1'>
                                        <a className='font-size__30 color--black clother--regular studio--twitter--title' href="https://www.linkedin.com/in/shan-selvaraj-6377a78b/" style={{cursor: "pointer", textDecorationThickness: 2, textUnderlineOffset: 2}}>LinkedIn</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                    {/* {this.renderFamily()} */}
                    <Row className="padding--5 backgroundColor--black" style={{ paddingTop: '9% ', paddingBottom: "9%" }}>
                        <Col className="no--mobile no-padding"><p className="font-family--clotherRegular p--paddingBottom-4  font-size__51 line-height__half Home--Feedback-description color--white">Excellence is our principle.<br /> Innovation and artfulness are our foundation.<br /> Conceptual and strategic thinking is<br /> how we differentiate.<br /> Collaboration and flexibility are our strengths.<br /> Good design is our purpose.</p></Col>
                        <Col className="mobile--view no-padding"><p className="font-family--clotherRegular p--paddingBottom-4  font-size__51 line-height__half Home--Feedback-description color--white">Excellence is our principle. Innovation and artfulness are our foundation. Conceptual and strategic thinking is how we differentiate. Collaboration and flexibility are our strengths. Good design is our purpose.</p></Col>
                    </Row>
                    <Row className="padding--5 backgroundColor--white" style={{ paddingTop: '9% ', paddingBottom: "0%" }}>
                        <Grid container spacing={3}>
                            <Grid item xs="12" sm="9">
                                <p className="font-family--clotherRegular color--black font-size__30">Press</p>
                                <p className="no--mobile color--black font-family--clotherRegular Home--Feedback-description font-size__51 line--height__05" style={{textDecoration:'underline', textDecorationThickness: 2, textUnderlineOffset: 2, marginRight:'5vw'}}><a className=" text--decoration color--black link" href="https://www.bcbusiness.ca/Small-Business-BC-Awards-announces-its-10-finalists">Small Business BC Awards announces its 10 finalists</a></p>
                                <p className="mobile--view color--black font-family--clotherRegular Home--Feedback-description font-size__51 line--height__05" style={{textDecoration:'underline', textDecorationThickness: 2, textUnderlineOffset: 2, marginRight:'5vw'}}><a className=" text--decoration color--black link" href="https://www.bcbusiness.ca/Small-Business-BC-Awards-announces-its-10-finalists">Small Business BC Awards announces its <br/>10 finalists</a></p>
                            </Grid>
                            <Grid item xs="12" sm="3" className="no--mobile">
                                <a href='https://smallbusinessbc.ca/awards/top-5-finalists/' style={{cursor:"pointer"}} ><img src={SBBC} alt="SBBC-Awards-PPC-Finalist-Badge" className='SBBC' /></a>
                            </Grid>
                            <Grid item xs="12" sm="3" className="mobile--view" style={{textAlign:'center', paddingTop:0}}>
                                <a href='https://smallbusinessbc.ca/awards/top-5-finalists/' style={{cursor:"pointer"}} ><img src={SBBC} alt="SBBC-Awards-PPC-Finalist-Badge" className='SBBC' /></a>
                            </Grid>
                        </Grid>
                    </Row>
                    <Row className="padding--5 backgroundColor--white" style={{ paddingBottom: "12%" }}>
                        <Grid container spacing={3}>
                            <Grid item xs="12" sm="9">
                                <p className="font-family--clotherRegular color--black font-size__30">Opinion</p>
                                <p className="no--mobile color--black font-family--clotherRegular Home--Feedback-description font-size__51 line--height__05" style={{textDecoration:'underline', textDecorationThickness: 2, textUnderlineOffset: 2, marginRight:'5vw'}}><a className=" text--decoration color--black link" href="https://www.rgd.ca/2022/03/21/business-perspectives-what-are-the-signs-that-a-company-should-start-a-rebranding-process.php">What are the signs that a company should start a rebrand?</a></p>
                                <p className="mobile--view color--black font-family--clotherRegular Home--Feedback-description font-size__51 line--height__05" style={{textDecoration:'underline', textDecorationThickness: 2, textUnderlineOffset: 2,  marginRight:'5vw'}}><a className=" text--decoration color--black link" href="https://www.rgd.ca/2022/03/21/business-perspectives-what-are-the-signs-that-a-company-should-start-a-rebranding-process.php">What are the signs that a company should start <br/>a rebrand?</a></p>
                            </Grid>
                        </Grid>
                    </Row>
                </div>
                <Footer />
            </div>
        )
    }
}

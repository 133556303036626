import React from "react"
import ContentLoader from "react-content-loader"
let isMobile = window.matchMedia("(max-width: 600px)").matches || window.matchMedia("(min-width: 600px)").matches 
let isTab = window.matchMedia("(min-width: 768px)").matches
let isTabPro= window.matchMedia("(min-width: 1024px)").matches
let isLaptop= window.matchMedia("(min-width: 1200px)").matches
let isLandscape= window.matchMedia("(orientation:landscape)").matches
const width = window.innerWidth/((isMobile&&!isLandscape)?1:2)
const height = (window.innerWidth/((isMobile&&!isLandscape)?1:2) )* 0.688

const Loader = (props) => (
    <ContentLoader
        speed={1.5}
        width={props.length==1?width*2:width}
        height={props.length==1?height*2:height}
        viewBox={"0 0 " + width + " "+height}
        backgroundColor="#d6d6d6"
        foregroundColor="#ecebeb"
        {...props}>
        <rect x="0" y="-9" rx="2" ry="2" width={width} height={height}/>
    </ContentLoader>
)

export default Loader

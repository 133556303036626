import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import VideoItem from '../../components/videoItem';
import "./style.css";
import seeAll from './../../assets/images/Buttons/allwork.svg';
import { Link } from 'react-router-dom';
import ListVideo from '../../services/videoList';
import CommonButton from '../commonbutton/commonbutton';

export default class RandomVideos extends Component {
    state = {
        videoList: ListVideo
    }

    componentDidMount = async () => {
        let videoList = [...ListVideo]
        videoList.splice(this.props.exclude, 1);
        let random = []
        let rndInt = Math.floor(Math.random() * (6 - 0 + 1)) + 0;
        let rndInt2 = Math.floor(Math.random() * (6 - 0 + 1)) + 0;
        if (rndInt2 == rndInt) {
            if (rndInt2 == 0) {
                rndInt2 = rndInt2 + 1
            } else {
                rndInt2 = rndInt2 - 1
            }
        }
        console.log(rndInt, rndInt2)
        random.push(videoList[rndInt2])
        random.push(videoList[rndInt])
        this.setState({ videoList: random })
    }

    handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    render() {
        const { videoList } = this.state;

        return (
            <div className="width--100 backgroundColor--black">
                <VideoItem videoList={videoList} />
                {/* <Container fluid className="backgroundColor--black color--white">
                    <Row className="padding--5">
                        <Col className="see--all">
                            <Link to="/work" onClick={() => this.handleClick()}>
                                <CommonButton value={{ bcolor: "white", arrow: "white", text: "See all Work" }} />
                            </Link>
                        </Col>
                    </Row>
                </Container> */}
            </div>
        )
    }
}

import React, { Component } from 'react'
import { HiOutlineArrowLeft } from "react-icons/hi";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import './style.css';
import arrow from '../../assets/images/arrow.svg';

export default class SquareButton extends Component {
    render() {
        const {buttonText, icon, noMargin} = this.props;
        return (
            <div className={`button--square align--center `+(icon=="arrow"?"justify--center":"justify--between")} 
            style={{margin: noMargin&&"1%", marginLeft: 0, 
            width:(buttonText=="See all videos"||buttonText=="BAED Website"||buttonText=="CCC Website")?250:icon=="arrow"?210:210}}>
                {
                    icon == "back" &&
                    <BsArrowLeftShort size={40} className="icon"/>
                }
                <div style={{margin: icon=="arrow"?"5%":"5% 20%"}}>{buttonText}</div>
                {
                    icon== "arrow" ?
                    <img src={arrow} width="21%"/>
                    : icon == "next" &&
                    <BsArrowRightShort size={20} className="icon"/>
                }
            </div>
        )
    }
}

let baed = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/videos%2FBAED%2FAnimation%20BAED_Thumbnail.mp4?alt=media')
let rchThumbnail = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/videos%2FARRIS/thumbnail.jpg?alt=media')
let baedThumbnail = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/videos%2FBAED/thumbnail.jpg?alt=media')
let cccThumbnail = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/videos%2FIcons/thumbnail.jpg?alt=media')
let snaptotesThumbnail = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/videos%2FSnaptotes/thumbnail.jpg?alt=media')
let snaptotes = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/videos%2FSnaptotes%2FSnaptotes_Thumbnail.mp4?alt=media')
let ccc = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/videos%2FIcons%2FIcons_Thumbnail.mp4?alt=media')
let rch = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/videos%2FARRIS%2FAnimation%20ARRIS%20_Thumbnail.mp4?alt=media')
let perAsperaAdAstra = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/videos%2FAspera%2Fcover.mp4?alt=media');
let perAsperaAdAstraThumbnail = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/videos%2FAspera%2Fthumbnail.mp4?alt=media');
let mavi = ("https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/videos%2Fmawi%2FAnimation%20mawi_Thumbnail.mp4?alt=media&token=7c22032d-d6d3-4c38-af0b-c9d390189178")
let einstein =("https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/videos%2FEinsteinsDreams%2FAnimation%20EinsteinsDream_Thumbnail.mp4?alt=media&token=3c174718-5550-4508-a8c9-46cb315527d6")
let spade = ("https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/videos%2Fspade%26plate%2FAnimation%20spade%26plate_Thumbnail.mp4.mp4?alt=media&token=15115b25-c961-498c-8c47-06ee5bc19cc1 ")
let einsteinThumbnail = ("https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/videos%2FEinsteinsDreams%2FEinsteins.png?alt=media&token=e90e8efd-3d1f-4d3e-a17f-f1d8d062ee2b")
let maviThumbnail = ("https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/videos%2Fmawi%2FMawi.png?alt=media&token=c65f1b3b-b19e-4437-b82c-945a8ee9864f")
let spadeThumbnail = ("https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/videos%2Fspade%26plate%2FSpade_Plate.png?alt=media&token=023146e6-cdb6-43d7-8c22-1db1d32928bd")
const ListVideo = [
    {
        "link": baed,
        "thumbnail": baedThumbnail,
        "theme": "dark",
        "title": "Be Active Every Day",
        "navigate": "baed",
        "subTitle": "Brand Design",
        "category": ["Branding", "UX/UI", "Motion graphics", "Illustration", "Packaging"],
    },
    {
        "link": spade,
        "thumbnail": spadeThumbnail,
        "theme": "dark",
        "navigate": "spade&plate",
        "title": "Spade & Plate",
        "subTitle": "Brand Design",
        "category": ["Branding", "Editorial"]
    },
    {
        "link": einstein,
        "thumbnail": einsteinThumbnail,
        "theme": "dark",
        "navigate": "einsteindreams",
        "title": "Einstein’s Dreams",
        "subTitle": "Art Direction, Editorial",
        "category": ["Signage",]
    },
    {
        "link": mavi,
        "thumbnail": maviThumbnail,
        "theme": "light",
        "navigate": "mawi",
        "title": "MAWI",
        "subTitle": "Strategy, Brand Design",
        "category": ["Illustration", "UX/UI"]
    },
    {
        "link": perAsperaAdAstraThumbnail,
        "thumbnail": perAsperaAdAstra,
        "theme": "light",
        "navigate": "perAsperaAdAstra",
        "title": "Per Aspera Ad Astra",
        "subTitle": "Art Direction, Packaging",
        "category": ["Branding", "Packaging"]
    },
    {
        "link": ccc,
        "thumbnail": cccThumbnail,
        "theme": "dark",
        "navigate": "coc",
        "title": "CCC Iconography",
        "subTitle": "Environment",
        "category": ["Signage",]
    },
    {
        "link": snaptotes,
        "thumbnail": snaptotesThumbnail,
        "theme": "light",
        "navigate": "snaptotes",
        "title": "Patterns Collection",
        "subTitle": "Illustration",
        "category": ["Illustration", "UX/UI"]
    },
    {
        "link": rch,
        "thumbnail": rchThumbnail,
        "theme": "light",
        "navigate": "arris",
        "title": "ARRIS Journal",
        "subTitle": "Art Direction, Editorial",
        "category": ["Illustration", "UX/UI"]
    },

    
]

export default ListVideo
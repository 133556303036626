import './App.css';
import Home from './screens/home';
import Works from "./screens/works"
import Services from "./screens/services"
import Studio from "./screens/studio"
import Contact from "./screens/contact"
import Gallery from './screens/Gallery';

function App() {
  return (
    <div className="App">
      {/* <Home/> */}
      <Works/>
      {/* <Services/> */}
      {/* <Contact/> */}
    </div>
  );
}

export default App;

import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import arrowbutton from "../../assets/images/Button_arrow.png"
import arrowbuttonblack from "../../assets/images/Button_arrow_in.png"

export default class CommonButton extends Component {

    render() {
        console.log(this.props.value.bcolor);
        return (
            <div style={{ marginTop: `${this.props.value.margin}`}}>
                <a href={this.props.value.HREF} className="common--buttonContainer" style={{ backgroundColor:this.props.value.arrow =="white"?"#fff":'#000'}}>
                    <Link to={this.props.value.link}>
                        <button 
                            onClick={this.props.onClick} 
                            className={'font-family--clotherRegular common--button' + (this.props.value.bcolor == "dark" ? " bcolor--black" : " bcolor--white")} 
                            style={{ textAlign:'center' }}
                            >
                                <span className='button--text'>{this.props.value.text}</span>
                        </button>
                        <span style={{backgroundColor:this.props.value.arrow =="white"?"#fff":'#000'}}>
                        {
                            this.props.value.arrow =="white" ?
                                <img src={arrowbutton} style={{marginTop:0}} className="arrowbutton" alt="arrowbutton" />
                            :
                                <img src={arrowbuttonblack} style={{marginTop:0}} className="arrowbutton" alt="arrowbutton" />
                        }
                        </span>
                    </Link>
                </a>
            </div>
        )
    }
}

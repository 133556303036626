import React, { Component } from 'react'
import { Grid } from "@material-ui/core";
import { Col, Container, Image, Row } from "react-bootstrap";
import SquareButton from './SquareButton.js';
import seeAll from './../../assets/images/Buttons/sellallvideos.svg';
import baedimg from './../../assets/images/Buttons/baed.svg';
import Navigator from './navigator.js';
import Based from './based';
import './style.css';
import Header from '../header';
import Footer from '../footer';
import firebase from '../../services/firebase';
import RandomVideos from '../randomVideos.js/index.js';
import BUSSINESS from "../../assets/images/Mavi/BusinessCards_MAWI.png"
import GIF from "../../assets/images/Mavi/Grid_Animation.gif"
import Envelope from "../../assets/images/Mavi/Envelopes_MAWI.png"
import ILLUSTRATION from "../../assets/images/Mavi/Illustration_MAWI.png"
import NOTEBOOK from "../../assets/images/Mavi/Notebooks_MAWI.png"
import WEBSITE from "../../assets/images/Mavi/Websitescreen_MAWI.png"
import SeeALL from './../../assets/images/Buttons/allwork.svg';
import { Link } from 'react-router-dom';
import colorsmobile from "../../assets/images/Mavi/Colors_Mawi_Mobile.png"
import CommonButton from '../commonbutton/commonbutton.js';
let image_1 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F1_Image.png?alt=media')
let image_2 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F2_Image.png?alt=media')
let image_3 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F3_Image.png?alt=media')
let image_4 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F4_Image.png?alt=media')
let image_5 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F5_Image.png?alt=media')
let image_6 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F6_Image.png?alt=media')
let image_7 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F7_Image.png?alt=media')
let image_8 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F8_Image.jpg?alt=media')
let image_9 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F9_Image.gif?alt=media')
let image_10 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F10_Image.png?alt=media')
let image_11 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F11_Image.mp4?alt=media')
let image_12 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F12_Image.gif?alt=media')
let image_13_1 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F13_1_Image.png?alt=media')
let image_13 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2FLanding%20–%2025.png?alt=media&token=dd5b00cc-44fa-45b3-940a-1c63b810c5f5')
let image_14 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F14_Image.png?alt=media')
let image_15 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F15_Image.png?alt=media')
let image_16 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F15_Image.gif?alt=media')
let banner = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F5_Banner.mp4?alt=media');
let mavi = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/videos%2Fmawi%2FAnimation%20mawi_Cover.mp4?alt=media&token=7eab7e4c-732c-4b85-a813-0c4a8f0ecd4a');


export default class MAWI extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drawerState: "null"
        };
    }

    componentDidMount() {
        var url_string = window.location.href
        var url = new URL(url_string);
        var filter = url.searchParams.get("filter");
        firebase.analytics().logEvent("BAED Page Viewed")
        if (filter) {
            let refactor = filter.split("%20").join(" ")
            this.setState({ currentFilter: refactor });
        }
    }

    toggleDrawer = () => {
        // const { drawerState } = this.state;
        // this.setState({
        //   drawerState:
        //     drawerState == "null" || drawerState == "hide--drawer"
        //       ? "show--drawer"
        //       : "hide--drawer",
        // });
    };

    handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    render() {
        const { drawerState } = this.state;
        return (
            <div className="Template2--template2 Template backgroundColor--black">
                <Header />
                <div className='cover--image backgroundColor--black'>
                    <video className="backgroundColor--black" playsInline preload="auto" src={mavi} width="100%" loop={true} autoPlay={true} muted={true} type="video/mp4" />
                </div>
                <Grid style={{ position: "relative" }}>
                    <p className="hero--Template1 color--white clother--regular font-size__110" style={{ marginBottom: '3%', paddingTop: '7%', marginLeft: '6%' }}>MAWI</p>
                    <Grid container style={{ marginBottom: '7%' }}>
                        <Grid xs={5} style={{ marginRight: '7%' }}>
                            <p className="color--white clother--regular font-size__30" style={{ margin: '5% 5% 20% 7vw', lineHeight: 1.6 }}>Strategy <br/>Brand Identity <br />Print Collateral <br />Web & Mobile<br /></p>
                        </Grid>
                        <Grid xs={6}>
                            <p className="color--white font-size__16 line-height__20 clother--regular" style={{ padding: '5% 25% 0% 7%' }}>
                                MAWI is an architecture studio that seeks to give people the power to express their true self through&nbsp;spaces. <br /><br />MAWI’s mark is inspired by the concept of MA in Japanese philosophy which translates as the pure and essential void between things. This brings emphasis to the space in a holistic way and reveals the concept of light fluidity.<br /><br /> <a className="text--decoration color--white link__white" href="https://www.mawistudio.com">www.mawistudio.com</a>
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container style={{ backgroundColor: `rgb(149, 149, 149)` }}>
                    <Grid item xs={12}>
                        <Image className="non--mobile" src={BUSSINESS} width="100%" />
                        <Image className="mobile" src={BUSSINESS} width="100%" />
                    </Grid>
                </Grid>
                <Grid container className="white--background">

                    <Grid container justify="center" className={"white--background"} style={{ paddingTop: '6%', paddingBottom: '5%', marginTop: 0 }}>
                        <Image className="non--mobile" src={GIF} style={{height:"100vh"}}/>
                        <Image className="mobile" src={GIF} width='40%'/>
                    </Grid>

                </Grid>
                <Grid className="centered--content__5 white--background">
                    <Image src={Envelope} width="100%" />
                </Grid>
                <Grid container style={{ backgroundColor: `rgb(149, 149, 149)` }} className="illustrator--content">
                    <Grid item xs={12}>
                        <Grid xs={12} className="half--content__right--text snaptotes--text" >
                            <p style={{ color: "#18191A" }} className="color--black line-height__20 font-size__16 clother--regular ">Illustration style and color palette. The colors are inspired from the environment and nature. The main colors are Shadow (Black) and Space (White). The secondary colors: Landscape, Terracota and Sunlight are introduced as highlights and for used for illustration.</p>
                        </Grid>
                        <Image className="non--mobile" src={ILLUSTRATION} width="100%" />
                        <Image className="mobile" style={{ paddingBottom: "50%", backgroundColor: "rgb(237,237,237)" }} src={ILLUSTRATION} width="100%" />
                    </Grid>
                </Grid>
                <Grid container style={{ backgroundColor: `rgb(255,255,255)` }}>
                    <Grid item xs={12} className='non--mobile'>
                        <div className='mavi--flex--box'>
                            <div className='mavi--flex--box--1'>
                                <div className='mavi--flex--box--1--sub--1'></div>
                                <div className='mavi--flex--box--1--sub--2'></div>
                            </div>
                            <div className='mavi--flex--box--2'>
                                <div className='mavi--flex--box--2--sub--1'></div>
                                <div className='mavi--flex--box--2--sub--2'></div>
                                <div className='mavi--flex--box--2--sub--3'></div>
                            </div>
                        </div>
                    </Grid>
                    <Image className="mobile" style={{paddingBottom:"10%"}} src={colorsmobile} width="100%" />
                </Grid>
                <Grid container  style={{ backgroundColor: `rgb(255,255,255)`,paddingBottom:"35%", paddingTop:0 }}>
                    {/* <Grid item xs={12} className="non--mobile">
                        <div className='mavi--flex--box--text'>
                            <div className='mavi--flex--box--1--text'>
                                <p style={{ color: "#18191A" }}
                                    className="color--black line-height__20 font-size__16 clother--regular ">
                                    Space #FFFFFF and Shadow #000000
                                </p>
                            </div>
                            <div className='mavi--flex--box--2--text'>
                                <div className='mavi--flex--box--2--text--1'>
                                    <p style={{ color: "#18191A" }}
                                        className="color--black line-height__20 font-size__16 clother--regular ">
                                        Landscape #006C2A
                                    </p>
                                </div>
                                <div className='mavi--flex--box--2--text--2'>
                                    <p style={{ color: "#18191A" }}
                                        className="color--black line-height__20 font-size__16 clother--regular ">
                                        Terracota #CE6600
                                    </p>
                                </div>
                                <div className='mavi--flex--box--2--text--3'>
                                    <p style={{ color: "#18191A" }}
                                        className="color--black line-height__20 font-size__16 clother--regular ">
                                        Sunlight #FEB500
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Grid> */}
                </Grid>
                <Grid item xs={12} style={{marginTop:'-25%'}}>
                    <Image src={NOTEBOOK} width="100%"/>
                </Grid>
                <Grid className="centered--content__5 " style={{ backgroundColor: `rgb(24,25,26)` }}>
                    <Image src={WEBSITE} width="100%" />
                    <Container fluid className="backgroundColor--black color--white">
                        <Row className="padding--5">
                            <Col className="see--all">
                                <CommonButton value={{ bcolor: "white", arrow: "white", text: "See website", margin: "0%"}} onClick={()=>{window.open("https://www.mawidesign.com")}}/>
                            </Col>
                        </Row>
                    </Container>
                </Grid>
                {/* <Navigator screen={2} prev="baed" next="snaptotes"/> */}
                <RandomVideos exclude={3}/>
                <Container fluid className="backgroundColor--black color--white">
                    <Row className="padding--5">
                        <Col className="see--all" onClick={this.handleClick}>
                            <CommonButton value={{ bcolor: "white", arrow: "white", text: "More Work", margin: "0%" ,link:"/work#"}} />
                        </Col>
                    </Row>
                </Container>
                {/* <Grid container>
                    <Grid item xs={12}>
                        <Based/>
                    </Grid>
                </Grid> */}
                <Footer />
            </div>
        )
    }
}

import React, { Component } from 'react'
import { Grid } from "@material-ui/core";
import { Col, Container, Image, Row } from "react-bootstrap";
import SquareButton from './SquareButton.js';
import seeAll from './../../assets/images/Buttons/sellallvideos.svg';
import baedimg from './../../assets/images/Buttons/baed.svg';
import Navigator from './navigator.js';
import Based from './based';
import './style.css';
import Header from '../header';
import Footer from '../footer';
import firebase from '../../services/firebase';
import RandomVideos from '../randomVideos.js/index.js';
import Intro from "../../assets/images/Einsteins Dreams/EinsteinsDreams_Intro.png"
import Einstein1 from "../../assets/images/Einsteins Dreams/EinsteinsDreams_02.png"
import Einstein2 from "../../assets/images/Einsteins Dreams/EinsteinsDreams_03.png"
import Einstein3 from "../../assets/images/Einsteins Dreams/EinsteinsDreams_04.png"
import Einstein4 from "../../assets/images/Einsteins Dreams/EinsteinsDreams_05.png"
import Einstein5 from "../../assets/images/Einsteins Dreams/EinsteinsDreams_06.png"
import Einstein7 from "../../assets/images/Einsteins Dreams/EinsteinsDreams_07.png"
import Einstein8 from "../../assets/images/Einsteins Dreams/EinsteinsDreams_08.png"
import Einstein9 from "../../assets/images/Einsteins Dreams/EinsteinsDreams_09.png"
import Einstein14 from "../../assets/images/Einsteins Dreams/EinsteinsDreams_014.png"
import Einstein11 from "../../assets/images/Einsteins Dreams/EinsteinsDreams_011.png"
import Einstein15 from "../../assets/images/Einsteins Dreams/EinsteinsDreams_015.png"
import Einstein10 from "../../assets/images/Einsteins Dreams/EinsteinsDreams_010.png"
import Einstein12 from "../../assets/images/Einsteins Dreams/EinsteinsDreams_012.png"
import Einstein13 from "../../assets/images/Einsteins Dreams/EinsteinsDreams_013.png"
import Einstein16 from "../../assets/images/Einsteins Dreams/EinsteinsDreams_016.png"
import ILLUSTRATION from "../../assets/images/Mavi/Illustration_MAWI.png"
import NOTEBOOK from "../../assets/images/Mavi/Notebooks_MAWI.png"
import WEBSITE from "../../assets/images/Mavi/Websitescreen_MAWI.png"
import SeeALL from './../../assets/images/Buttons/allwork.svg';
import { Link } from 'react-router-dom';
import CommonButton from '../commonbutton/commonbutton.js';
let image_1 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F1_Image.png?alt=media')
let image_2 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F2_Image.png?alt=media')
let image_3 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F3_Image.png?alt=media')
let image_4 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F4_Image.png?alt=media')
let image_5 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F5_Image.png?alt=media')
let image_6 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F6_Image.png?alt=media')
let image_7 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F7_Image.png?alt=media')
let image_8 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F8_Image.jpg?alt=media')
let image_9 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F9_Image.gif?alt=media')
let image_10 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F10_Image.png?alt=media')
let image_11 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F11_Image.mp4?alt=media')
let image_12 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F12_Image.gif?alt=media')
let image_13_1 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F13_1_Image.png?alt=media')
let image_13 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2FLanding%20–%2025.png?alt=media&token=dd5b00cc-44fa-45b3-940a-1c63b810c5f5')
let image_14 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F14_Image.png?alt=media')
let image_15 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F15_Image.png?alt=media')
let image_16 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F15_Image.gif?alt=media')
let banner = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F5_Banner.mp4?alt=media');
let einstein = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/videos%2FEinsteinsDreams%2FAnimation%20EinsteinsDream_Cover.mp4?alt=media&token=9fc3825d-2e28-4078-97d2-40e0dbf3d7cd');


export default class EINSTEIN extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drawerState: "null"
        };
    }

    componentDidMount() {
        var url_string = window.location.href
        var url = new URL(url_string);
        var filter = url.searchParams.get("filter");
        firebase.analytics().logEvent("BAED Page Viewed")
        if (filter) {
            let refactor = filter.split("%20").join(" ")
            this.setState({ currentFilter: refactor });
        }
    }

    toggleDrawer = () => {
        // const { drawerState } = this.state;
        // this.setState({
        //   drawerState:
        //     drawerState == "null" || drawerState == "hide--drawer"
        //       ? "show--drawer"
        //       : "hide--drawer",
        // });
    };

    handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    render() {
        const { drawerState } = this.state;
        return (
            <div className="Template2--template2 Template backgroundColor--black">
                <Header theme={'dark'}/>
                <div className='cover--image backgroundColor--black'>
                    <video className="backgroundColor--black" playsInline preload="auto" src={einstein} width="100%" loop={true} autoPlay={true} muted={true} type="video/mp4" />
                </div>
                <Grid style={{ position: "relative" }}>
                    <p className="hero--Template1 color--white clother--regular font-size__110" style={{ marginBottom: '3%', paddingTop: '7%', marginLeft: '6%', lineHeight:1.2 }}>Einstein’s Dreams</p>
                    <Grid container style={{ marginBottom: '7%' }}>
                        <Grid xs={5} style={{ marginRight: '7%' }}>
                            <p className="color--white clother--regular font-size__30" style={{ margin: '5% 5% 20% 7vw', lineHeight: 1.6 }}>Art Direction<br/>Editorial</p>
                        </Grid>
                        <Grid xs={6}>
                            <p className="color--white font-size__16 line-height__20 clother--regular" style={{ padding: '5% 25% 0% 7%' }}>
                                Einstein’s Dreams was an international bestseller novel by Alan Lightman. Each chapter explores a dream about time that Einstein had during his work on the theory of relativity in 1905. <br /><br />
                                This book design contains five of the novel’s chapters. Each chapter’s structure was inspired by a concept and is a work of experimentation that challenges publication design rules. <br /><br />
                                The chapters can be read individually as booklets and are tied together in their spine. All booklets are held inside a slipcase.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid style={{ position: "relative", backgroundColor: "rgb(33,34,35)" }}>
                    <div className='award--box'>
                        <div className='award--box--1'>
                            <p className="color--white clother--regular font-size__30">Awards & <br />Recognitions</p>
                        </div>
                        <div className='award--box--2'>
                            <p className="color--white font-family--montserrat font-size__30" >SCAD <span style={{ color: "#fff", fontWeight: "bolder" }}>secession</span> 2016<br />SILVER AWARD</p>
                        </div>
                    </div>
                </Grid>
                <Grid container style={{ backgroundColor: `rgb(149, 149, 149)` }}>
                    <Grid item xs={12}>
                        <Image className="non--mobile" src={Intro} width="100%" />
                        <Image className="mobile" src={Intro} width="100%" />
                    </Grid>
                </Grid>
                <Grid className="centered--content__5 white--background">
                    <Image src={Einstein1} width="100%" />
                </Grid>
                <Grid className="centered--content__5 white--background" style={{ paddingTop: "0" }}>
                    <Image src={Einstein2} width="100%" />
                </Grid>
                <Grid item xs={12} className="white--background" style={{ color: "#18191A" }}>
                    <Grid xs={12} className="half--content" >
                        <p className="no--mobile color--black line-height__20 font-size__16 clother--regular">“The tragedy of this world is that no one is happy, whether stuck in a<br /> time of pain or of joy. The tragedy of this world is that everyone is<br /> alone. For a life in the past cannot be shared with the present. Each<br /> person who gets stuck in time gets stuck alone.”</p>
                        <p className="mobile--view color--black line-height__20 font-size__16 clother--regular">“The tragedy of this world is that no one is happy, whether stuck in a time of pain or of joy. The tragedy of this world is that everyone is alone. For a life in the past cannot be shared with the present. Each person who gets stuck in time gets stuck alone.”</p>
                    </Grid>
                </Grid>
                <Grid container style={{ backgroundColor: `rgb(149, 149, 149)` }}>
                    <Grid item xs={12}>
                        <Image className="non--mobile" src={Einstein3} width="100%" />
                        <Image className="mobile" src={Einstein3} width="100%" />
                    </Grid>
                </Grid>
                <Grid className="centered--content__10 white--background">
                    <Image src={Einstein4} width="100%" />
                </Grid>
                <Grid className="centered--content__10 white--background" style={{ paddingTop: "0" }}>
                    <Image src={Einstein5} width="100%" />
                </Grid>
                <Grid item xs={12} className="white--background" style={{ color: "#18191A" }}>
                    <Grid xs={12} className="half--content" >
                        <p className="no--mobile color--black line-height__20 font-size__16 clother--regular">
                        “And just as all things will be repeated in the future, all things now<br /> happening happened a million times before. Some few people in<br /> every town, in their dreams, are vaguely aware that all has occurred<br /> in the past.”</p>
                        <p className="mobile--view color--black line-height__20 font-size__16 clother--regular">
                        “And just as all things will be repeated in the future, all things now happening happened a million times before. Some few people in every town, in their dreams, are vaguely aware that all has occurred in the past.”</p>
                    
                    </Grid>
                </Grid>
                <Grid container style={{ backgroundColor: `rgb(149, 149, 149)` }}>
                    <Grid item xs={12}>
                        <Image className="non--mobile" src={Einstein10} width="100%" />
                        <Image className="mobile" src={Einstein10} width="100%" />
                    </Grid>
                </Grid>
                <Grid className="centered--content__5 white--background">
                    <Image src={Einstein14} width="100%" />
                </Grid>
                <Grid item xs={12} className="white--background" style={{ color: "#18191A" }}>
                    <Grid xs={12} className="half--content" >
                        <p className="no--mobile color--black line-height__20 font-size__16 clother--regular">
                            “Now she prepares for a pirouette, right leg moving back to fourth<br /> position, pushing off on one foot, arms coming in to speed the tum. She<br /> is precision. She is a clock. In her mind, while she dances, she thinks she<br /> should have floated a little on one leap, but she cannot float because<br /> her movements are not hers.”
                        </p>
                        <p className="mobile--view color--black line-height__20 font-size__16 clother--regular">
                            “Now she prepares for a pirouette, right leg moving back to fourth position, pushing off on one foot, arms coming in to speed the tum. She is precision. She is a clock. In her mind, while she dances, she thinks she should have floated a little on one leap, but she cannot float because her movements are not hers.”
                        </p>
                    </Grid>
                </Grid>
                <Grid container style={{ backgroundColor: `rgb(149, 149, 149)` }}>
                    <Grid item xs={12}>
                        <Image className="non--mobile" src={Einstein11} width="100%" />
                        <Image className="mobile" src={Einstein11} width="100%" />
                    </Grid>
                </Grid>
                <Grid className="centered--content__5 white--background">
                    <Image src={Einstein15} width="100%" />
                </Grid>
                <Grid item xs={12} className="white--background" style={{ color: "#18191A" }}>
                    <Grid xs={12} className="half--content" >
                        <p className="no--mobile color--black line-height__20 font-size__16 clother--regular">
                            “Grandparents never die, nor do great- grandparents, great-aunts and<br /> great-uncles, great-great-aunts, and so on, back through the<br /> generations, all alive and offering advice. Sons never escape from the<br /> shadows of their fathers. Nor do daughters of their mothers. No one<br /> ever comes into his own.”
                        </p>
                        <p className="mobile--view color--black line-height__20 font-size__16 clother--regular">
                            “Grandparents never die, nor do great- grandparents, great-aunts and great-uncles, great-great-aunts, and so on, back through the generations, all alive and offering advice. Sons never escape from the shadows of their fathers. Nor do daughters of their mothers. No one ever comes into his own.”
                        </p>
                    </Grid>
                </Grid>
                <Grid container style={{ backgroundColor: `rgb(149, 149, 149)` }}>
                    <Grid item xs={12}>
                        <Image className="non--mobile" src={Einstein12} width="100%" />
                        <Image className="mobile" src={Einstein12} width="100%" />
                    </Grid>
                </Grid>
                <Grid className="centered--content__10 white--background">
                    <Image src={Einstein7} width="100%" />
                </Grid>
                <Grid className="centered--content__10 white--background" style={{ paddingTop: "0" }}>
                    <Image src={Einstein16} width="100%" />
                </Grid>
                <Grid item xs={12} className="white--background" style={{ color: "#18191A" }}>
                    <Grid xs={12} className="half--content" >
                        <p className="no--mobile color--black line-height__20 font-size__16 clother--regular ">
                            “These adventuresome souls come down to the lower world for days at a time, lounge under the trees that grow in the valleys, swim leisurely in the lakes that lie at warmer altitudes, roll on level ground. They hardly look at their watches and cannot tell you if it is Monday or Thursday. When the others rush by them and scoff, they just smile.”
                        </p>
                        <p className="mobile--view color--black line-height__20 font-size__16 clother--regular ">
                            “These adventuresome souls come down to the lower world for days at a time, lounge under the trees that grow in the valleys, swim leisurely in the lakes that lie at warmer altitudes, roll on level ground. They hardly look at their watches and cannot tell you if it is Monday or Thursday. When the others rush by them and scoff, they just smile.”
                        </p>
                    </Grid>
                </Grid>
                <Grid container style={{ backgroundColor: `rgb(149, 149, 149)` }}>
                    <Grid item xs={12}>
                        <Image className="non--mobile" src={Einstein13} width="100%" />
                        <Image className="mobile" src={Einstein13} width="100%" />
                    </Grid>
                </Grid>
                <Grid className="centered--content__10 white--background">
                    <Image src={Einstein8} width="100%" />
                </Grid>
                <Grid className="centered--content__10 white--background" style={{ paddingTop: "0" }}>
                    <Image src={Einstein9} width="100%" />
                </Grid>
                {/* <Navigator screen={2} prev="baed" next="snaptotes"/> */}
                <RandomVideos exclude={2} />
                <Container fluid className="backgroundColor--black color--white">
                    <Row className="padding--5">
                        <Col className="see--all">
                            <div onClick={() => this.handleClick()}>
                                <CommonButton value={{ bcolor: "white", arrow: "white", text: "More Work", margin: "0%", link: "/work#" }} />
                            </div>
                        </Col>
                    </Row>
                </Container>
                {/* <Grid container>
                    <Grid item xs={12}>
                        <Based/>
                    </Grid>
                </Grid> */}
                <Footer />
            </div>
        )
    }
}

import firebase from "firebase/app";
import 'firebase/analytics'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyDBqEgsF-gDBb4oh9aTqkhRvXtr7hAt0L4",
  authDomain: "tardigrada-website.firebaseapp.com",
  databaseURL: "https://tardigrada-website-default-rtdb.firebaseio.com",
  projectId: "tardigrada-website",
  storageBucket: "tardigrada-website.appspot.com",
  messagingSenderId: "748336295503",
  appId: "1:748336295503:web:842fac33476f173a5a443a",
  measurementId: "G-EQNBFLBHMW"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Home from './screens/home';
import Works from "./screens/works"
import Services from "./screens/services"
import Studio from "./screens/studio"
import Contact from "./screens/contact"
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import BAED from './components/workTemplates/baed';
import COC from './components/workTemplates/coc';
import ARRIS from './components/workTemplates/arris';
import SNAPTOTES from './components/workTemplates/snaptotes';
import Aspera from './components/workTemplates/aspera';
import MAWI from './components/workTemplates/mawi';
import EINSTEIN from './components/workTemplates/einstein';
import SPADEPLATE from './components/workTemplates/spade & plate';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/home">
            <Home />
          </Route>
          <Route path="/work">
            <Works />
          </Route>
          <Route path="/services">
            <Services />
          </Route>
          <Route path="/studio">
            <Studio />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/baed">
            <BAED/>
          </Route>
          <Route path="/spade&plate">
            <SPADEPLATE/>
          </Route>
          spade&plate
          <Route path="/arris">
            <ARRIS/>
          </Route>
          <Route path="/einsteindreams">
            <EINSTEIN/>
          </Route>
          <Route path="/mawi">
            <MAWI/>
          </Route>
          <Route path="/snaptotes">
            <SNAPTOTES/>
          </Route>
          <Route path="/coc">
            <COC />
          </Route>
          <Route path="/perAsperaAdAstra">
            <Aspera />
          </Route>
        </Switch>
   </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { Component } from 'react'
import { Grid } from "@material-ui/core";
import { Image } from "react-bootstrap";
import SquareButton from './SquareButton.js';
import seeAll from './../../assets/images/Buttons/sellallvideos.svg';
import { Col, Container, Row } from "react-bootstrap";

import baedimg from './../../assets/images/Buttons/baed.svg';
import Navigator from './navigator.js';
import Based from './based';
import './style.css';
import Header from '../header';
import Footer from '../footer';
import firebase from './../../services/firebase';
import RandomVideos from '../randomVideos.js/index.js';
import CommonButton from '../commonbutton/commonbutton.js';

let image_1 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FSNAPTOTES%2F1_Image.png?alt=media')
let image_1_1 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FSNAPTOTES%2F1_1_Image.png?alt=media')
let image_2 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FSNAPTOTES%2F2_Image.jpg?alt=media&token=7f5c109b-2ea0-40e9-92c5-fa766676c1ac')
let image_2_1 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FSNAPTOTES%2F2_1_Image.png?alt=media')
let image_3 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FSNAPTOTES%2F3_Image.png?alt=media')
let image_3_1 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FSNAPTOTES%2F3_1_Image.png?alt=media')
let image_4 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FSNAPTOTES%2F4_Image.png?alt=media')
let image_4_1 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FSNAPTOTES%2F4_1_Image.png?alt=media')
let image_5 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FSNAPTOTES%2F5_Image.png?alt=media')
let image_5_1 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FSNAPTOTES%2F5_1_Image.png?alt=media')
let image_6 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FSNAPTOTES%2F6_Image.png?alt=media')
let image_6_1 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FSNAPTOTES%2F6_1_Image.png?alt=media')
let image_7 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FSNAPTOTES%2F7_Image.png?alt=media')
let image_8 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FSNAPTOTES%2F8_Image.png?alt=media')
let image_9 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FSNAPTOTES%2F9_Image.gif?alt=media')
let image_10 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FSNAPTOTES%2F10_Image.png?alt=media')
let image_11 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FSNAPTOTES%2F11_Image.mp4?alt=media')
let image_12 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FSNAPTOTES%2F12_Image.gif?alt=media')
let image_13 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FSNAPTOTES%2F13_Image.png?alt=media')
let image_14 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FSNAPTOTES%2F14_Image.png?alt=media')
let image_15 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FSNAPTOTES%2F15_Image.png?alt=media')
let image_16 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FSNAPTOTES%2F15_Image.gif?alt=media')
let banner = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FSNAPTOTES%2F5_Banner.mp4?alt=media');
let snaptotes = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/videos%2FSnaptotes%2FSnaptotes_Cover.mp4?alt=media');


export default class SNAPTOTES extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drawerState: "null"
        };
    }

    componentDidMount() {
        var url_string = window.location.href
        var url = new URL(url_string);
        var filter = url.searchParams.get("filter");
        firebase.analytics().logEvent("BAED Page Viewed")
        if(filter){
            let refactor = filter.split("%20").join(" ")
            this.setState({ currentFilter: refactor });
        }
    }

    toggleDrawer = () => {
        // const { drawerState } = this.state;
        // this.setState({
        //   drawerState:
        //     drawerState == "null" || drawerState == "hide--drawer"
        //       ? "show--drawer"
        //       : "hide--drawer",
        // });
    };

    handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    render() {
        const { drawerState } = this.state;
        return (
            <div className="Template2--template2 Template backgroundColor--black snaptotes">
                <Header/>
                <div className='cover--image backgroundColor--black'>
                    <video className="backgroundColor--black" playsInline preload="auto" src={snaptotes} width="100%" loop={true} autoPlay={true} muted={true} type="video/mp4"/>
                </div>
                <p className="hero--Template1 color--white clother--regular font-size__110" style={{marginBottom:'3%', marginTop:'10%', marginLeft:'6%', lineHeight:1.2}}>Snaptotes Patterns</p>
                <Grid container style={{marginBottom:'15%'}}>
                    <Grid xs={5} style={{marginRight:'7%'}}>
                        <p className="color--white clother--regular font-size__30" style={{margin:'5% 5% 20% 7vw', lineHeight:1.6}}>Illustration<br/></p>
                        <p className="font-size__16 line-height__20 clother--regular" style={{ color: "#E2E2E2", width:"55%", margin:'5% 40% 40% 7vw'}}>Tardigrada design studio in collaboration with John Yalanda Román</p>
                    </Grid>
                    <Grid xs={6}>
                        <p className="color--white font-size__16 line-height__20 clother--regular" style={{padding:'5% 20% 0% 7%'}}>Seamless illustration patterns for a new collection. It includes a realistic floral, flora & fauna and a timeless french inspired geometric pattern.</p>
                    </Grid>
                </Grid>
                <Grid container xs={12}>
                    <Grid item xs={12} className="mobile--content--changes" style={{backgroundColor:"rgb(168,165,157)"}}>
                        <Grid xs={12} className="half--content snaptotes--text" >
                            <p className="color--black line-height__20 font-size__16 clother--regular">Realistic Floral: <br/>Our pattern features a delightful combination of peonies, bee palms, bees, and bleeding hearts flowers. The peonies bring a touch of elegance with their lush petals, while the bee palms add a tropical vibe. The busy bees and delicate bleeding hearts add a charming touch to the design, making it perfect for a nature-inspired pattern.</p>
                        </Grid>
                        <Image className="non--mobile" src={image_1} width="100%"/>
                        <Image className="mobile" src={image_1_1} width="100%"/>
                    </Grid>
                    <Grid item xs={12}>
                    <Image className="non--mobile" src={image_2} width="100%"/>
                        <Image className="mobile" src={image_2_1} width="100%"/>
                    </Grid>
                    <Grid item xs={12}  className="mobile--content--changes" style={{backgroundColor:"rgb(129,142,192)"}}>
                        <Grid container className="snaptotes--text" style={{marginBottom: "-45vh"}}>
                            <Grid xs={1}></Grid>
                            <Grid xs={5} className="text--alignment--mobile">
                                <p className="color--black clother--regular font-size__16 line-height__20" style={{marginTop:"10vh", marginLeft:"3vh"}}>Flora & Fauna <br/>The pattern features the vibrant bee balm flowers, the majestic green jay, and the delicate dragonfly. The bee balms' bright red and pink hues create a striking contrast to the blue jay's vibrant blue feathers, while the dragonfly's intricate wings add a touch of ethereal beauty. The result is a stunning design that celebrates the beauty of nature.</p>
                            </Grid>
                            <Grid xs={5}>
                            </Grid>
                        </Grid>
                        <Image className="non--mobile" src={image_3} width="100%"/>
                        <Image className="mobile" src={image_3_1} width="100%"/>
                    </Grid>
                    <Grid item xs={12}>
                        <Image className="non--mobile" src={image_4} width="100%"/>
                        <Image className="mobile" src={image_4_1} width="100%"/>
                    </Grid>
                    <Grid item xs={12} className="mobile--content--changes" style={{backgroundColor:"rgb(21,131,143)"}}>
                        <Grid xs={12} className="half--content snaptotes--text" >
                            <p className="color--black line-height__20 font-size__16 clother--regular">Timeless pattern:<br/>This timeless pattern combines the iconic Fleur de lis motif with elegant French tile patterns. The intricate design features delicate curves and sharp edges, creating a sense of balance and harmony. This versatile pattern was used in a range of products, adding a touch of&nbsp;sophistication.</p>
                        </Grid>
                        <Image className="non--mobile" src={image_5} width="100%"/>
                        <Image className="mobile" src={image_5_1} width="100%"/>
                    </Grid>
                    <Grid item xs={12}>
                        <Image className="non--mobile" src={image_6} width="100%"/>
                        <Image className="mobile" src={image_6_1} width="100%"/>
                    </Grid>
                    <Grid item xs={12} className="white--background" style={{paddingTop:'5%'}}>
                        <Image src={image_7} width="100%"/>
                    </Grid>
                </Grid>
                <RandomVideos exclude={6}/>
                <Container fluid className="backgroundColor--black color--white">
                    <Row className="padding--5">
                        <Col className="see--all">
                            <div onClick={() => this.handleClick()}>
                                <CommonButton value={{ bcolor: "white", arrow: "white", text: "More Work", margin: "0%", link: "/work" }} />
                            </div>
                        </Col>
                    </Row>
                </Container>
                {/* <Navigator screen={2} prev="arris" next="coc"/> */}
                {/* <Grid container>
                    <Grid item xs={12}>
                        <Based/>
                    </Grid>
                </Grid> */}
                <Footer/>
            </div>
        )
    }
}

import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import SquareButton from './SquareButton'
import './style.css';
import back from './../../assets/images/Buttons/back.svg'
import next_ from './../../assets/images/Buttons/next.svg';

export default class Navigator extends Component {

    onClick=()=>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    render() {
        const {next, prev} = this.props
        return (
            <div className={"container--horizontal justify--center"} style={{padding:'10% 0%'}}>
                <Link to={prev} className="button navigation--button" style={{all:'unset',textAlign:'right', margin:"0.6%"}} onClick={this.onClick}>
                    {/* <SquareButton buttonText={"Back"} noMargin={true} icon="back"/> */}
                    <img className="work--button" src={back} width="80%"/>
                </Link>
                <Link to={next} className="button navigation--button" style={{all:'unset',textAlign:'left', margin:"0.6%" }} onClick={this.onClick}>
                    {/* <SquareButton buttonText={"Next"} noMargin={true} icon="next"/> */}
                    <img className="work--button" src={next_} width="80%"/>
                </Link>
            </div>
        )
    }
}

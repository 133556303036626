import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import logo from './../../assets/images/Logo.svg'
import './style.css'
import firebase from './../../services/firebase';
import TGDA from "../../assets/images/Logotgda.png"

export default class Footer extends Component {

    state = {
        footerSlide: 0.3,
        whiteSlide: 0.2,
        height: 0,
        contactsList: ["Vancouver based design studio.", "page", "social"],
        contacts: [
            {
                "name": "We acknowledge that with roots in Vancouver, our work takes place on the unceded territories of the xʷməθkwəy̓əm (Musqueam), Skwxwú7mesh (Squamish), and Səl̓ílwətaɬ (Tsleil-Waututh) Nations.",
            },
        ],
        page: [
            {
                "name": "Work",
                "link": "https://www.instagram.com/tardigradadesign/"
            },
            {
                "name": "Services",
                "link": "https://twitter.com/tgdadesign"
            },
            {
                "name": "Studio",
                "link": "https://www.facebook.com/tardigradadesign"
            },
            {
                "name": "Contact",
                "link": "https://www.linkedin.com/company/tardigrada-design-studio/"
            }
        ],
        social: [
            {
                "name": "Instagram",
                "link": "https://www.instagram.com/tardigradadesign/"
            },
            {
                "name": "LinkedIn",
                "link": "https://www.linkedin.com/company/tardigrada-design-studio/"
            },
            {
                "name": "Twitter",
                "link": "https://twitter.com/tgdadesign"
            },
            {
                "name": "Facebook",
                "link": "https://www.facebook.com/tardigradadesign"
            },

        ]
    }

    scrollTop = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    // componentDidMount() {
    //     window.addEventListener('scroll', this.listenToChange)
    // }

    // componentWillUnmount() {
    //     window.removeEventListener('scroll', this.listenToChange)
    // }

    // listenToChange = (e) => {
    //     const divScroll = document.getElementById("footer").getBoundingClientRect()
    //     const height = Math.round(divScroll.bottom) - document.documentElement.clientHeight
    //     let isMobile = window.matchMedia("(max-height: 600px)").matches || window.matchMedia("(min-height: 600px)").matches
    //     let isTab = window.matchMedia("(min-height: 768px)").matches
    //     let isTabPro = window.matchMedia("(min-height: 1024px)").matches
    //     let isLaptop = window.matchMedia("(min-width: 1200px)").matches
    //     let isLandscape = window.matchMedia("(orientation:landscape)").matches
    //     if ((isMobile && !isTab && !isTabPro && !isLaptop) && isLandscape) {
    //         this.setState({ footerSlide: 0.6, whiteSlide: 0.8 })
    //     } else if (height < divScroll.height) {
    //         const scale = ((100 - ((height / divScroll.height) * 100) * 0.6) / 100 - 0.4)
    //         this.setState({ footerSlide: scale, whiteSlide: scale + 0.2 })
    //     }
    //     this.setState({ height: divScroll.height })
    // }

    renderContact = () => {
        const { contacts, page, social, contactsList } = this.state
        return contactsList.map((item, index) => {
            return <div className="contact--list">
                {item == "Vancouver based design studio." &&
                    <span className="clother--regular color--white font-size__2">{item}</span>
                }
                <div className="footer--contacts">
                    {
                        this.renderContactList(item == "Vancouver based design studio." ? contacts : (item == "page" ? page : social))
                    }

                </div>
            </div>
        })
    }

    onClick = (item) => {
        firebase.analytics().logEvent(item + " Clicked")
    }

    renderContactList = (data) => {
        return data.map((item, index) => {
            return <p className={"noto--regular color--white contact--item font-size__1" + (item.name == "tardigrada@tardigradastudio.com" ? " footer--email" : "")}><a onClick={() => this.onClick(item.name)} style={{ all: 'unset' }} href={item.link} target="_blank">{item.name}</a></p>
        })
    }

    render() {
        const { footerSlide, height, whiteSlide } = this.state;
        return (
            <div className='footer backgroundColor--black'>
                <div className='footer--content'>
                    <p className="font-family--clotherRegular  Home--title  font-size__700 no--mobile">Distinctive brands for passionate people</p>
                    <p className="font-family--clotherRegular  Home--title  font-size__700 mobile--view" style={{paddingBottom:'12vw'}}>Distinctive brands for passionate people</p>
                    <div className='footer--content--description no--mobile'>
                        <div className='footer--content--description--1'>
                            <p className="no--mobile clother--regular color--white font-size__2 line-height_05">Award-winning design studio based <br/>in Vancouver, BC.</p>
                            <p className="mobile--view clother--regular color--white font-size__2 line-height_05">Award-winning design studio based in Vancouver, BC.</p>
                            <p className='footer--content--description--1--desc clother--regular line-height__19 color--white font-size__1 footer--final' style={{paddingTop:'5vw'}}>We acknowledge that our work takes place on the unceded territories of the xʷməθkwəy̓əm (Musqueam), Skwxwú7mesh (Squamish), and Səl̓ílwətaɬ (Tsleil-Waututh) Nations.</p>
                        </div>
                        <div className='footer--content--description--2' >
                            <p className="clother--regular color--white contact--item font-size__2 footer--email">
                                <a style={{ all:"unset", cursor:"pointer" }}href="/work" target="_blank" >
                                    Work
                                </a>
                            </p>
                            <p className="clother--regular color--white contact--item font-size__2 footer--email">
                                <a style={{ all:"unset",cursor:"pointer" }} href="/services" target="_blank">
                                    Services
                                </a>
                            </p>
                            <p className="clother--regular color--white contact--item font-size__2 footer--email">
                                <a style={{ all:"unset",cursor:"pointer" }} href="/studio" target="_blank">
                                    Studio
                                </a>
                            </p>
                            <p className="clother--regular color--white contact--item font-size__2 footer--email">
                                <a style={{ all:"unset",cursor:"pointer" }} href="/contact" target="_blank">
                                    Contact
                                </a>
                            </p>
                            <p className="clother--regular color--white contact--item font-size__1 footer--final" style={{textAlign:'left'}}>
                                © 2023 tardigrada design studio</p>
                        </div>
                        <div className='footer--content--description--3'>
                            <p className="clother--regular color--white contact--item font-size__2 footer--email">
                                <a style={{ all:"unset", cursor:"pointer" }} href="https://www.instagram.com/tardigradadesign/" target="_blank">
                                    Instagram
                                </a>
                            </p>
                            <p className="clother--regular color--white contact--item font-size__2 footer--email">
                                <a style={{ all:"unset",cursor:"pointer" }} href="https://www.linkedin.com/company/tardigrada-design-studio/" target="_blank">
                                    LinkedIn
                                </a>
                            </p>
                            <p className="clother--regular color--white contact--item font-size__2 footer--email">
                                <a style={{ all:"unset",cursor:"pointer" }} href="https://twitter.com/tgdadesign" target="_blank">
                                    Twitter
                                </a>
                            </p>
                            <p className="clother--regular color--white contact--item font-size__2 footer--email">
                                <a style={{ all:"unset",cursor:"pointer" }} href="https://www.facebook.com/tardigradadesign" target="_blank">
                                    Facebook
                                </a>
                            </p>
                            <p onClick={this.scrollTop} className="clother--regular color--white contact--item font-size__2 footer--email font-weight__bold footer--final" style={{marginTop:'6vw', cursor:'pointer'}}>
                                tgda.
                            </p>
                        </div>
                    </div>
                    <div className='footer--content--description mobile--view' style={{borderTop:'2px solid #fff'}}>
                        <div className='footer--content--description--1'>
                            <p className="clother--regular color--white font-size__2" style={{marginTop:30}}>Award-winning design studio based in Vancouver, BC.</p>
                        </div>
                        <div className='footer--content--description--2' >
                            <p className="clother--regular color--white contact--item font-size__2 footer--email">
                                <a style={{ cursor:"pointer", textDecoration:'underline'}} className="color--white link__white" href="/work" target="_blank" >
                                   Work
                                </a>
                            </p>
                            <p className="clother--regular color--white contact--item font-size__2 footer--email">
                                <a style={{ cursor:"pointer", textDecoration:'underline'  }} className="color--white link__white" href="/services" target="_blank">
                                    Services
                                </a>
                            </p>
                            <p className="clother--regular color--white contact--item font-size__2 footer--email">
                                <a style={{ cursor:"pointer", textDecoration:'underline'  }} className="color--white link__white"href="/studio" target="_blank">
                                    Studio
                                </a>
                            </p>
                            <p className="clother--regular color--white contact--item font-size__2 footer--email">
                                <a style={{ cursor:"pointer", textDecoration:'underline'  }} className="color--white link__white" href="/contact" target="_blank">
                                    Contact
                                </a>
                            </p>
                            
                        </div>
                        <div className='footer--content--description--1'>
                            <p className='footer--content--description--1--desc clother--regular line-height__19 color--white font-size__1 footer--final'>We acknowledge that our work takes place on the unceded territories of the xʷməθkwəy̓əm (Musqueam), Skwxwú7mesh (Squamish), and Səl̓ílwətaɬ (Tsleil-Waututh) Nations.</p>
                        </div>
                        <div className='footer--content--description--3'>
                            <p className="clother--regular color--white contact--item font-size__2 footer--email">
                                <a style={{ cursor:"pointer",textDecoration:'underline' }} className="color--white link__white" href="https://www.instagram.com/tardigradadesign/" target="_blank">
                                    Instagram
                                </a>
                            </p>
                            <p className="clother--regular color--white contact--item font-size__2 footer--email">
                                <a style={{ cursor:"pointer",textDecoration:'underline' }} className="color--white link__white" href="https://www.linkedin.com/company/tardigrada-design-studio/" target="_blank">
                                    LinkedIn
                                </a>
                            </p>
                            <p className="clother--regular color--white contact--item font-size__2 footer--email">
                                <a style={{ cursor:"pointer",textDecoration:'underline' }} className="color--white link__white" href="https://twitter.com/tgdadesign" target="_blank">
                                    Twitter
                                </a>
                            </p>
                            <p className="clother--regular color--white contact--item font-size__2 footer--email">
                                <a style={{ cursor:"pointer",textDecoration:'underline' }} className="color--white link__white" href="https://www.facebook.com/tardigradadesign" target="_blank">
                                    Facebook
                                </a>
                            </p>
                            <p onClick={this.scrollTop} className="non--mobile clother--regular color--white contact--item font-size__2 footer--email font-weight__bold footer--final" style={{marginTop:'15vw', cursor:'pointer'}}>
                                tgda.
                            </p>
                            <p onClick={this.scrollTop} className="mobile--view clother--regular color--white contact--item font-size__2 footer--email font-weight__bold footer--final" style={{marginTop:'15vw', cursor:'pointer'}}>
                                <img src={TGDA} width="22%"/>
                            </p>
                            <p className="clother--regular color--white contact--item font-size__1 footer--final" style={{textAlign:'left'}}>
                                © 2023 tardigrada design studio</p>
                        </div>
                    </div>
                    
                    {/* <div className='footer--content--description'>
                        <div className='footer--content--description--1'>
                            <a href='https://smallbusinessbc.ca/awards/top-5-finalists/'style={{cursor:"pointer"}} ><img src={SBBC} alt="SBBC-Awards-PPC-Finalist-Badge" className='SBBC' /></a>
                        </div>
                        <div className='footer--content--description--2 flex--column'>
                            <p className="clother--regular color--white contact--item font-size__1">
                                © 2023 tardigrada design studio</p>
                        </div>
                        <div className='footer--content--description--3 flex--column'>
                            <p className="clother--regular color--white contact--item font-size__2 footer--email font-weight__bold">
                                tgda.
                            </p>
                        </div>
                    </div> */}
                </div>
            </div>
        )
    }
}

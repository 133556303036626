import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import { Image } from "react-bootstrap";
import { BsArrowRight } from "react-icons/bs";
import ccc from '../../assets/images/Buttons/ccc.svg';
import back from '../../assets/images/Buttons/back.svg';
import next from '../../assets/images/Buttons/ccc.svg';
import SquareButton from "./SquareButton.js";
import Navigator from "./navigator.js";
import Based from "./based.js";
import Footer from "../footer/index.js";
import Header from "../header";
import firebase from './../../services/firebase';
import RandomVideos from "../randomVideos.js";
import { Col, Container, Row } from "react-bootstrap";
import CommonButton from '../commonbutton/commonbutton.js';
let cover = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FCOC%2Fcover.jpg?alt=media');
let image_02 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FCOC%2F02_Image.png?alt=media');
let image_03 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FCOC%2F03_Image_Gridicons.gif?alt=media');
let image_04 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FCOC%2F04_Image.png?alt=media');
let image_05 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FCOC%2F05_Image.png?alt=media');
let image_06 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FCOC%2F06_Image.png?alt=media');
let image_07 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FCOC%2F07_Image.png?alt=media');
let image_08 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FCOC%2F08_Image.jpg?alt=media');
let image_09 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FCOC%2F01_Image.jpg?alt=media');
let image_10 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FCOC%2F09_Image.png?alt=media');
let image_11 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FCOC%2F010_Image.png?alt=media');
let image_12 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FCOC%2F011_Image.png?alt=media');
let image_13 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FCOC%2F012_Image.png?alt=media');
let image_14 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FCOC%2F013_Image.png?alt=media');
let image_15 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FCOC%2F014_Image.png?alt=media');
let image_16 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FCOC%2F024_Image.png?alt=media');
let image_17 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FCOC%2F015_Image.png?alt=media');
let image_18 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FCOC%2F027_Image.png?alt=media');
let image_19 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FCOC%2F028_Image.png?alt=media');
let image_20 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FCOC%2F016_Image.png?alt=media');
let image_21 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FCOC%2F017_Image.png?alt=media');
let image_22 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FCOC%2F018_Image.png?alt=media');
let image_23 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FCOC%2F019_Image.png?alt=media');
let image_24 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FCOC%2F020_Image.png?alt=media');
let image_25 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FCOC%2F021_Image.png?alt=media');
let image_26 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FCOC%2F023_Image.png?alt=media');
let image_27 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FCOC%2F025_Image.png?alt=media');
let image_28 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FCOC%2F026_Image.png?alt=media');
let image_29 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FCOC%2F030_Image.png?alt=media');
let coc = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/videos%2FIcons%2FIconos_Cover.mp4?alt=media');


class COC extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drawerState: "null"
        };
    }

    componentDidMount() {
        let path = window.location.href;
        let split = path.split("=");
        firebase.analytics().logEvent("COC Page Viewed")

        if(split[1]){
            let refactor = split[1].split("%20").join(" ")
            this.setState({ currentFilter: refactor });
        }
    }

    toggleDrawer = () => {
        // const { drawerState } = this.state;
        // this.setState({
        //   drawerState:
        //     drawerState == "null" || drawerState == "hide--drawer"
        //       ? "show--drawer"
        //       : "hide--drawer",
        // });
    };

    handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    render() {
        const { drawerState } = this.state;
        return (
        <div className="Template1--template1 Template backgroundColor--black">
            <Header theme="dark"/>
            <div className='cover--image'>
                <video playsInline preload="auto" src={coc} width="100%" loop={true} autoPlay={true} muted={true} type="video/mp4"/>
            </div>
            <div>
                <p className="hero--Template1 color--white clother--regular font-size__110" style={{margin:'10% 0% 3% 6%', lineHeight:1.2}}>Chamber of Commerce</p>
                <Grid container style={{marginBottom:'5%'}}>
                    <Grid xs={5} style={{marginRight:'5%'}}>
                        <p className="color--white font-size__30 clother--regular" style={{ margin:'5% 5% 5% 7vw', color:"", lineHeight:1.6}}>Signage & Wayfinding<br/>Environmental Graphics</p>
                        <p className="color--white font-size__16 line-height__19 clother--regular" style={{color:'#e2e2e2', margin:'2% 55% 20% 7vw'}}>Tardigrada design studio in collaboration with <span style={{margin:0, marginBottom:'5%'}}>Velové</span> Branding Studio</p>
                    </Grid>
                    <Grid xs={6}>
                        <p className="color--white font-size__16 line-height__20 clother--regular" style={{padding:'5% 20% 0% 7%'}}>The Chamber of Commerce in Cali (<a href="https://www.ccc.org.co/" target="_blank" style={{all:'unset',cursor:'pointer', margin:0}}>Cámara de Comercio de Cali</a>) is one of the most important public-private organizations in southwestern Colombia. The CCC is committed to accompany and challenge entrepreneurs and local businesses for their growth and the region&nbsp;development. <br/><br/>Iconographic system development to transform their visual identity with consistency through all their platforms, including way-finding system and digital communication.</p>
                    </Grid>
                </Grid>
                <div className="white--background" style={{paddingBottom:'7%'}}>
                    <div className="half--content__right blue--background">
                        <p className="color--white font-size__16 line-height__20 clother--regular" style={{marginRight:'15%'}}>Based on the morphological features: vertices, distances and line weight, of the typography AmpleSoft, the primary typography from the Chamber of Commerce Identity&nbsp;system.</p>
                    </div>
                    <Image src={image_02} width={'100%'}/>
                    <div className="half--content">
                        <p className="color--black font-size__16 line-height__20 clother--regular" style={{marginRight:'15%', marginLeft:"0%"}}>A 60x60px grid for design consistency, guidance and on the basis of usage on digital platforms.</p>
                    </div>
                    <Image src={image_03} width={'100%'}/>
                    <Image src={image_04} width={'100%'}/>
                </div>
                <div>
                    <div className="half--content">
                        <p className="color--white font-size__16 line-height__20 clother--regular">The iconography was implemented in their digital platforms, meetings, events and way-finding system.</p>
                    </div>
                    <div className="centered--content__5">
                        <Image src={image_05} width={"100%"}/>
                    </div>
                    <Grid container className="justify--center align--center full--width" style={{paddingBottom:'20%'}}>
                        <CommonButton value={{ bcolor: "white", arrow: "white", text: "CCC Website", margin: "0%"}} onClick={()=>window.open("https://www.ccc.org.co/")}/>
                    </Grid>
                </div>
                <Grid container className="white--background" style={{margin:0}}>
                    <Grid xs={6}>
                        <Image src={image_06} width="78%" style={{position:'absolute', marginTop:'-20%', marginLeft:'-18%'}}/>
                    </Grid>
                    <Grid xs={6}>
                        <Image src={image_07} width="80%" style={{margin:'10% 5% 0% 5%'}}/>
                    </Grid>
                </Grid>
                <div className="centered--content__40 white--background">
                    <Image src={image_09} width="100%" style={{marginBottom:'5%'}}/>
                    <Image src={image_08} width="100%"/>
                </div>
                
                <div className="centered--content__5" style={{margin:'1% 0%'}}>
                    <Image src={image_11} height="100%" width="100%" style={{marginBottom:'6vw'}}/>
                    <Image src={image_29} width={"100%"} style={{marginBottom:'6vw'}}/>
                    <Image src={image_17} width="100%"/>
                </div>
                <div className="centered--content__40 white--background" style={{paddingTop:'10%', paddingBottom:'10%'}}>
                    <Image src={image_13} height="100%" width="100%"/>
                </div>

                <Grid container className="white--background">
                    <Grid item xs={12}>
                        <Image src={image_12} width="100%"/>
                    </Grid>
                </Grid>
                <Grid container className="white--background" style={{paddingTop:'5%'}}>
                    <Grid item xs={12}>
                        <Image src={image_20} width="100%"/>
                    </Grid>
                    <Grid item xs={12}>
                        <Image src={image_21} width="100%"/>
                    </Grid>
                </Grid>
                <div className="centered--content__5 white--background">
                    <Image src={image_22} width="100%"/>
                </div>
                <div className={"centered--content__40"}>
                    <Image src={image_15} height="100%" width="100%"/>
                    <Image src={image_23} width="100%" style={{marginTop:'3%'}}/>
                    <Image src={image_24} width="100%" style={{marginTop:'3%'}}/>
                    <Image src={image_25} width="100%" style={{marginTop:'3%'}}/>
                    <Image src={image_26} width="100%" style={{marginTop:'3%'}}/>
                    <Image src={image_27} width="100%" style={{marginTop:'3%'}}/>
                    <Image src={image_16} width="100%" style={{marginTop:'3%'}}/>
                </div>
                <Grid container>
                    <Grid item xs={12}>
                        <Image src={image_28} width="100%"/>
                    </Grid>
                </Grid>
                <div className="centered--content__5">
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Image src={image_18} width="100%"/>
                            {/* <img className="work--button" src={back} width="80%"/> */}
                        </Grid>
                        <Grid item xs={6}>
                            <Image src={image_19} width="100%"/>
                            {/* <img className="work--button" src={next} width="80%"/> */}
                        </Grid>
                    </Grid>
                </div>
                {/* <Navigator screen={1} next="baed" prev="snaptotes"/> */}
                <RandomVideos exclude={5}/>
                <Container fluid className="backgroundColor--black color--white">
                    <Row className="padding--5">
                        <Col className="see--all">
                            <div onClick={() => this.handleClick()}>
                                <CommonButton value={{ bcolor: "white", arrow: "white", text: "More Work", margin: "0%", link: "/work" }} />
                            </div>
                        </Col>
                    </Row>
                </Container>
                {/* <Grid container>
                    <Grid item xs={12}>
                        <Based/>
                    </Grid>
                </Grid> */}
                <Footer/>
            </div>
        </div>
        );
    }
}

export default COC;

import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Logo from '../logo';
import menu from './../../assets/images/Icons/Burger_menu.svg'
import './style.css';
import firebase from './../../services/firebase'

export default class Menu extends Component {

    state= {
        menuActive:false,
        menuAnimated:false,
        tempHide:false,
        menuList:["Work", "Services", "Studio", "Contact"],
        load:false
    }

    toggleMenu=()=>{
        const {menuActive, menuAnimated, tempHide} = this.state;
        this.setState({menuActive:!menuActive})
        setTimeout(() => {
            this.setState({menuAnimated:!menuAnimated})
        }, menuAnimated?200:500);
        this.setState({ load:true})
        if(!menuActive){
            this.setState({tempHide:true, load:true})
            setTimeout(() => {
                this.setState({tempHide:false})
            }, menuActive?1400:700);
        }
        firebase.analytics().logEvent("Menu Clicked")
    }

    toggleMenuClick=()=>{
        this.toggleMenu()
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    renderMenu=()=>{
        const {menuList} = this.state;
        return menuList.map((item, index)=>{
            return <div className="menu--item">
                <Link to={"/"+item.toLowerCase()} onClick={this.toggleMenuClick}>
                    <p className="color--black clother--regular no-margin no-padding line-height__half">{item}</p>
                </Link>
            </div>
        })
    }

    render() {
        const {menuActive, menuAnimated, tempHide, load} = this.state
        return (
            <div className="cover">
                <div >
                    {
                        !tempHide?
                        <div className={"menu--icon"} onClick={this.toggleMenu}>
                            <img src={menu} width="28vh" height="28vh"/>
                        </div>:
                        <div className={"dark--icon"}>
                            <img src={menu} style={{opacity:0}} width="28vh" height="28vh"/>
                        </div>
                    }
                    {
                        load&&
                        <div className={menuActive?"menu--icon_active":"menu--icon_inactive"} onClick={this.toggleMenu}></div>
                    }
                </div>
                {
                    menuAnimated&&
                    <div className="menu--content">
                        <Logo theme="dark"/>
                        <div className="menu--list">
                            {this.renderMenu()}
                        </div>
                    </div>
                }
            </div>
        )
    }
}

import React, { Component } from 'react'
import { Grid } from "@material-ui/core";
import { Image } from "react-bootstrap";
import SquareButton from './SquareButton.js';
import seeAll from './../../assets/images/Buttons/sellallvideos.svg';
import baedimg from './../../assets/images/Buttons/baed.svg';
import Navigator from './navigator.js';
import Based from './based';
import './style.css';
import Header from '../header';
import Footer from '../footer';
import firebase from './../../services/firebase';
import RandomVideos from '../randomVideos.js/index.js';
import { Col, Container, Row } from "react-bootstrap";
import CommonButton from '../commonbutton/commonbutton.js';
let cover = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FBAED%2F01_Image.png?alt=media')
let image_2 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FBAED%2F02_Image.png?alt=media')
let image_3 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FBAED%2F03_Image.gif?alt=media')
let image_4 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FBAED%2F04_Image.png?alt=media')
let image_6 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FBAED%2F06_Image.png?alt=media')
let image_7 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FBAED%2F07_Image.gif?alt=media')
let image_8 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FBAED%2F08_Image.jpg?alt=media')
let image_9 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FBAED%2F09_Image.png?alt=media')
let image_10 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FBAED%2F010_Image.png?alt=media')
let image_11 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FBAED%2F011_Image.png?alt=media')
let image_12 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FBAED%2F012_Image.png?alt=media')
let image_13 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FBAED%2F013_Image.png?alt=media')
let image_14 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FBAED%2F014_Image.png?alt=media')
let image_15 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FBAED%2F015_Image.png?alt=media')
let image_16 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FBAED%2F015_Image.gif?alt=media')
let banner = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FBAED%2F05_Banner.mp4?alt=media');
let baed = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/videos%2FBAED%2FAnimation%20BAED_Cover.mp4?alt=media');


export default class BAED extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drawerState: "null"
        };
    }

    componentDidMount() {
        var url_string = window.location.href
        var url = new URL(url_string);
        var filter = url.searchParams.get("filter");
        firebase.analytics().logEvent("BAED Page Viewed")
        if(filter){
            let refactor = filter.split("%20").join(" ")
            this.setState({ currentFilter: refactor });
        }
    }

    toggleDrawer = () => {
        // const { drawerState } = this.state;
        // this.setState({
        //   drawerState:
        //     drawerState == "null" || drawerState == "hide--drawer"
        //       ? "show--drawer"
        //       : "hide--drawer",
        // });
    };

    handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }


    render() {
        const { drawerState } = this.state;
        return (
            <div className="Template2--template2 Template backgroundColor--black">
                <Header/>
                <div className='cover--image'>
                    <video playsInline preload="auto" src={baed} width="100%" loop={true} autoPlay={true} muted={true} type="video/mp4"/>
                </div>
                <p className="hero--Template1 color--white clother--regular font-size__110" style={{marginBottom:'3%', marginTop:'10%', marginLeft:'6%', lineHeight:1.2}}>Be Active Every Day</p>
                <Grid container style={{marginBottom:'5%'}}>
                    <Grid xs={5} style={{marginRight:'7%'}}>
                        <p className="color--white clother--regular font-size__30" style={{margin:'5% 5% 20% 7vw', lineHeight:1.6}}>Visual identity<br/>Illustration<br/>Print Collateral<br/>Packaging<br/>Motion Graphics<br/>Web & Mobile</p>
                        {/* <p className="medium--text__white" style={{fontSize:20, margin:'8% 55% 20% 0%'}}>Tardigrada design studio<br/>&#8212;<br/>Vancouver, BC</p> */}
                    </Grid>
                    <Grid xs={6}>
                        <p className="color--white font-size__16 line-height__20 clother--regulars" style={{padding:'5% 20% 0% 7%'}}>Be Active Every Day is an annual public health initiative fully funded by <a className="hover__white" style={{all:'unset', cursor:'pointer'}} onClick={()=>window.open("https://www.doctorsofbc.ca/")}>Doctors of BC</a> to help kids (ages 5 to 11) be more active and make healthy choices. In partnership with the local elementary schools, Doctors across BC coordinate a month-long challenge to kids.<br/><br/>Along with the health campaign, we redesigned the ‘Be Active Every Day’ logo to be in alignment with the Doctors of BC brand. The theme for this year’s Be Active Every Day challenge was ‘Play It Forward’. The board game, illustrations, website, and animated videos were designed with COVID safety measures in mind.'</p>
                    </Grid>
                </Grid>
                <Grid container className="cyan--background">
                    <Grid xs={1}></Grid>
                    <Grid xs={5}>
                    </Grid>
                    <Grid xs={5}>
                        <p className="color--white clother--regular line-height__20 font-size__16" style={{margin:'20% 10% 7% 7%'}}>Dynamism, fun and diversity are the main concepts explored for the Be Active Every Day logo redesign.</p>
                    </Grid>
                    <Grid xs={1}></Grid>
                </Grid>
                <Grid container className="white--background">
                    <Grid item xs={12}>
                        <Image src={image_2} width="100%"/>
                    </Grid>
                    <Grid container justify="center" className={"white--background"} style={{paddingTop:'6%', paddingBottom:'5%', marginTop:0}}>
                        <Image src={image_3} width="40%"/>
                    </Grid>
                    <Grid item xs={12} style={{paddingBottom:'5%'}}>
                        <Image src={image_4} width="100%"/>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6} style={{padding:"7%"}}>
                        <p className="color--white line-height__20 font-size__16 clother--regular">Series of animated videos to launch the campaign. They communicate the meaning of 5-2-1-0 in Be Active Every&nbsp;Day.</p>
                    </Grid>
                    <Grid item xs={12} className="centered--content_5">
                        {/* <video src={image_5} autoPlay width="100%" height="100%"/> */}
                    </Grid>
                </Grid>
                <div>
                    <iframe width="90%" style={{margin:"0% 5% 3% 5%"}} height="600" src="https://www.youtube.com/embed/0E63DSCY-Ww" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                    </iframe>
                    <div className="full--width flex justify--center align--center" style={{margin: "5% 0% 5% 0%"}}>
                        <CommonButton value={{ bcolor: "white", arrow: "white", text: "See all Videos", margin: "0%"}} onClick={()=>window.open("https://www.youtube.com/playlist?list=PLg3FvFQU-i5Shaz-HWi8UTbwLibMORIGr")}/>
                    </div>
                    <Grid container>
                        <Grid item xs={6} style={{padding:"7%"}}>
                            <p className="color--white line-height__20 font-size__16 clother--regular">Website for doctors and schools to sign up to the program, and provide resources for parents and kids.</p>
                        </Grid>
                    </Grid>
                </div>
                <div className="centered--content__5" style={{paddingTop:'2%'}}>
                    <video playsInline preload="auto" src={banner} width="100%" loop autoPlay style={{marginBottom:'5%'}} type="video/mp4"/>
                    <Image src={image_6} width="100%"/>
                </div>
                <div className="full--width flex justify--center align--center" style={{margin: "2% 0% 12% 0%"}}>
                    <CommonButton value={{ bcolor: "white", arrow: "white", text: "BAED Website", margin: "0%"}} onClick={()=>window.open("https://be-active.ca/")}/>
                </div>
                <Grid container className="white--background">
                    <Grid item xs={12}>
                        <Image src={image_7} width="100%"/>
                    </Grid>
                </Grid>
                <div className="white--background centered--content__20">
                    <Image src={image_8} width="100%" />
                </div>
                <Grid container className="white--background  centered--content__5 justify--center" style={{paddingBottom:0, paddingTop:0}}>
                    {/* <Grid item xs={6} style={{paddingLeft:'11%'}}>
                        <Image src={image_9} width="90%"/>
                    </Grid>
                    <Grid item xs={6}></Grid> */}
                    <Grid item xs={6} style={{paddingRight:'10%', paddingTop:'5%', paddingBottom:'2%'}}>
                        <p className="color--black line-height__20 font-size__16 clother--regular">The ZCard is a comic-like guide for the kids to familiarize with the characters and the instructions to play the Be Active board game. The back side provides ‘the challenge chart’ for tracking kids daily activity during the challenge.</p>
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={12} style={{paddingTop:'5%'}}>
                        <Image src={image_11} width="100%" style={{marginBottom:'5%'}}/>
                        <Image src={image_10} width="100%"/>
                    </Grid>
                </Grid>
                <Grid container className="white--background">
                    <Grid item xs={6} className="white--background" style={{padding:"15% 7% 3% 7%"}}>
                        <p className="color--black line-height__20 font-size__16 clother--regular">The Be Active Board game was conceptualized and brought to reality. There are two versions of the game - a Deluxe version for the challenge winners that include 3D printed characters in a game box, and a paper version with easy to build characters and dice.</p>
                    </Grid>
                    <Grid item xs={12}>
                        <Image src={image_15} width="100%"/>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <Image src={image_12} width="100%" height="100%"/>
                    </Grid>
                </Grid>
                <Grid container spacing={3} className="centered--content__5 white--background" style={{paddingTop:'5%', paddingBottom:'2%'}}>
                    <Grid item xs={6}>
                        <Image src={image_13} width="100%"/>
                    </Grid>
                    <Grid item xs={6}>
                        <Image src={image_14} width="100%"/>
                    </Grid>
                </Grid>
                <Grid container style={{paddingBottom:"0%"}}>
                    <Grid item xs={12}>
                        <Image src={image_16} width="100%"/>
                    </Grid>
                </Grid>
                {/* <Navigator screen={2} prev="coc" next="arris"/> */}
                <RandomVideos exclude={0}/>
                <Container fluid className="backgroundColor--black color--white">
                    <Row className="padding--5">
                        <Col className="see--all">
                            <div onClick={() => this.handleClick()}>
                                <CommonButton value={{ bcolor: "white", arrow: "white", text: "More Work", margin: "0%", link: "/work" }} />
                            </div>
                        </Col>
                    </Row>
                </Container>
                {/* <Grid container>
                    <Grid item xs={12}>
                        <Based/>
                    </Grid>
                </Grid> */}
                <Footer/>
            </div>
        )
    }
}

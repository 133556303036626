import React from 'react'
import { Link } from 'react-router-dom'
import './style.css'

export default function Logo(props) {
    return (
        <Link to="/" onClick={()=>{
            window.location.href="/"
            window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })}}>
            <h5 className={(props.theme=="light"?"color--white":"color--black")+" clother--bold logo"}>tardigrada design studio</h5>
        </Link>
    )
}

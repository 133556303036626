import React, { Component } from 'react'
import BAED from '../../components/workTemplates/baed'
import COC from '../../components/workTemplates/coc'
import firebase from './../../services/firebase'

export default class Gallery extends Component {

    componentDidMount=async()=>{
        firebase.analytics().logEvent("Gallery Page Viewed")
    }

    render() {
        return (
            <div>
                <COC/>
            </div>
        )
    }
}

import React, { Component } from 'react'
import { Grid } from "@material-ui/core";
import { Image } from "react-bootstrap";
import SquareButton from './SquareButton.js';
import seeAll from './../../assets/images/Buttons/sellallvideos.svg';
import baedimg from './../../assets/images/Buttons/baed.svg';
import Navigator from './navigator.js';
import Based from './based';
import './style.css';
import Header from '../header';
import Footer from '../footer';
import firebase from './../../services/firebase';
import RandomVideos from '../randomVideos.js/index.js';
import { Col, Container, Row } from "react-bootstrap";
import CommonButton from '../commonbutton/commonbutton.js';
let image_1 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F1_Image.png?alt=media')
let image_2 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F2_Image.png?alt=media')
let image_3 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F3_Image.png?alt=media')
let image_4 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F4_Image.png?alt=media')
let image_5 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F5_Image.png?alt=media')
let image_6 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F6_Image.png?alt=media')
let image_7 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F7_Image.png?alt=media')
let image_8 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F8_Image.jpg?alt=media')
let image_9 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F9_Image.gif?alt=media')
let image_10 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F10_Image.png?alt=media')
let image_11 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F11_Image.mp4?alt=media')
let image_12 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F12_Image.gif?alt=media')
let image_13_1 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F13_1_Image.png?alt=media')
let image_13 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2FLanding%20–%2025.png?alt=media&token=dd5b00cc-44fa-45b3-940a-1c63b810c5f5')
let image_14 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F14_Image.png?alt=media')
let image_15 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F15_Image.png?alt=media')
let image_16 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F15_Image.gif?alt=media')
let banner = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FARRIS%2F5_Banner.mp4?alt=media');
let arris = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/videos%2FARRIS%2FAnimation%20ARRIS_Cover.mp4?alt=media');


export default class ARRIS extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drawerState: "null"
        };
    }

    componentDidMount() {
        var url_string = window.location.href
        var url = new URL(url_string);
        var filter = url.searchParams.get("filter");
        firebase.analytics().logEvent("BAED Page Viewed")
        if(filter){
            let refactor = filter.split("%20").join(" ")
            this.setState({ currentFilter: refactor });
        }
    }

    toggleDrawer = () => {
        // const { drawerState } = this.state;
        // this.setState({
        //   drawerState:
        //     drawerState == "null" || drawerState == "hide--drawer"
        //       ? "show--drawer"
        //       : "hide--drawer",
        // });
    };

    handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    render() {
        const { drawerState } = this.state;
        return (
            <div className="Template2--template2 Template backgroundColor--black">
                <Header/>
                <div className='cover--image backgroundColor--black'>
                    <video className="backgroundColor--black" playsInline preload="auto" src={arris} width="100%" loop={true} autoPlay={true} muted={true} type="video/mp4"/>
                </div>
                <Grid style={{position:"relative"}}>
                    <p className="hero--Template1 color--white clother--regular font-size__110" style={{marginBottom:'3%', paddingTop:'10%', marginLeft:'6%'}}>ARRIS Journal</p>
                    <Grid container style={{marginBottom:'10%'}}>
                        <Grid xs={5} style={{marginRight:'7%'}}>
                            <p className="color--white clother--regular font-size__30" style={{margin:'5% 5% 20% 7vw', lineHeight:1.6}}>Art Direction<br/>Editorial</p>
                            <p className="font-size__16 line-height__20 clother--regular arris--text1">Tardigrada design studio in collaboration with Dr. Robin Williams, Chair of the Architectural History program at Savannah College of Art and Design.</p>
                        </Grid>
                        <Grid xs={6}>
                            {/* <Image className="arris--image" src={image_10} width="15%"/> */}
                            <p className="color--white font-size__16 line-height__20 clother--regular" style={{padding:'5% 20% 0% 7%'}}>The new design on the Southeast Chapter of the Society of Architectural Historians (ARRIS) Journal aims for a contemporary look in balance with its established guidelines. The publication is created for an academic audience that involves architectural historians, architects and historic preservationists in the United States. <br/><br/>The design was awarded with an Honorable mention in the Books category by the International Design Awards in&nbsp;2015.</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid style={{ position: "relative", backgroundColor: "rgb(33,34,35)" }}>
                    <div className='award--box'>
                        <div className='award--imagebox--1'>
                            <p className="color--white clother--regular font-size__30">Awards & <br />Recognitions</p>
                        </div>
                        <div className='award--imagebox--2'>
                            <div className='award--image--content'>
                                <img src={image_10} style={{height:'auto'}} alt="award" className='award--image' />
                            </div>

                        </div>
                    </div>
                </Grid>
                <Grid container style={{backgroundColor:`rgb(149, 149, 149)`}}>
                    <Grid item xs={12}>
                        <p className=" clother--regular arris--text2 font-size__16 line-height__20">A black and white full-photography cover presents the beauty of Architectural pieces in the new face of the ARRIS Journal.</p>
                        <Image src={image_1} width="100%"/>
                    </Grid>
                    <Grid item xs={12}>
                        <Image className="non--mobile" src={image_13} width="100%"/>
                        <Image className="mobile" src={image_13_1} width="100%"/>
                    </Grid>
                </Grid>
                <Grid className="centered--content__5">
                    <Image src={image_2} width="100%"/>
                </Grid>
                <Grid container>
                    <Grid item xs={12} className="half--content" style={{paddingTop:"10%"}}>
                        <p className="color--white line-height__20 font-size__16 clother--regular">The article abstracts are collected together at the beginning
of the volume to facilitate a quick perusal of the article contents.</p>
                    </Grid>
                </Grid>
                <div className="centered--content__5" style={{paddingTop:'1%', paddingBottom:'15%'}}>
                    <Image src={image_3} width="100%"/>
                </div>
                <Grid container className="white--background  centered--content__5 justify--center" style={{paddingBottom:"15%", paddingTop:0}}>
                    <Grid item xs={6} style={{paddingRight:'10%', paddingTop:'15%', paddingBottom:'2%'}}>
                        <p className="color--black line-height__20 font-size__16 clother--regular">The digital version of the journal (e-book) offers different ways to navigate and interact throughout the pages. It also allows to create engaging content with videos and sound.</p>
                    </Grid>
                    <Grid item xs={6}>
                        <Image className="top-25" src={image_12} width="100%" />
                    </Grid>
                    <Grid item xs={12} style={{paddingTop:'5%'}}>
                        <video className="backgroundColor--black" playsInline preload="auto" src={image_11} width="100%" loop={true} autoPlay={true} muted={true} type="video/mp4"/>
                    </Grid>
                </Grid>
                <Grid container spacing={3} className="centered--content__5 background--black" style={{paddingTop:'8%', paddingBottom:'2%'}}>
                    <Grid item xs={12}>
                        <Image src={image_4} width="100%"/>
                    </Grid>
                </Grid>
                <Grid container spacing={3} className="centered--content__5 background--black" style={{paddingTop:'4%', paddingBottom:'2%'}}>
                    <Grid item xs={12}>
                        <Image src={image_5} width="100%"/>
                    </Grid>
                </Grid>
                <Grid container spacing={3} className="centered--content__5 background--black" style={{paddingTop:'4%', paddingBottom:'18%'}}>
                    <Grid item xs={12}>
                        <Image src={image_6} width="100%"/>
                    </Grid>
                </Grid>
                <Grid container className="white--background  centered--content__5 justify--center" style={{paddingBottom:"35%", paddingTop:0}}>
                    <Grid item xs={6}>
                        <Image className="top-25" src={image_9} width="100%" />
                    </Grid>
                    <Grid item xs={6} style={{paddingRight:'10%', paddingTop:'15%'}}>
                        <p className="color--black line-height__20 font-size__16 clother--regular">Hyperlinks to direct from pages to endnotes.</p>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{marginTop:'-25%'}}>
                    <Image src={image_7} width="100%"/>
                </Grid>
                {/* <Navigator screen={2} prev="baed" next="snaptotes"/> */}
                <RandomVideos exclude={7}/>
                <Container fluid className="backgroundColor--black color--white">
                    <Row className="padding--5">
                        <Col className="see--all">
                            <div onClick={() => this.handleClick()}>
                                <CommonButton value={{ bcolor: "white", arrow: "white", text: "More Work", margin: "0%", link: "/work" }} />
                            </div>
                        </Col>
                    </Row>
                </Container>
                {/* <Grid container>
                    <Grid item xs={12}>
                        <Based/>
                    </Grid>
                </Grid> */}
                <Footer/>
            </div>
        )
    }
}

import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Header from '../../components/header';
import VideoItem from '../../components/videoItem';
import "./style.css"
import Footer from '../../components/footer';
import firebase from './../../services/firebase'
import ListVideo from '../../services/videoList';

export default class Work extends Component {

    state = {
        filters: ["Branding", "UX/UI", "Signage", "Motion Graphics", "Illustration", "Editorial", "Packaging"],
        videoList: ListVideo,
        Backuplist: [],
        selected:null
    }

    componentDidMount = () => {
        firebase.analytics().logEvent("Works Page Viewed")
        this.setState({ Backuplist: this.state.videoList })
    }

    filterList = (filter) => {
        const { Backuplist,selected } = this.state;
        if(selected==filter){
            this.setState({ videoList: Backuplist, selected:null })
        } else {
            let filteredData = []
            this.setState({ videoList: [] })
            Backuplist.map((item, index) => {
                if (item.category.includes(filter)) {
                    filteredData.push(item)
                }
            })
            this.setState({ videoList: filteredData.length>0?filteredData:Backuplist, selected:filter })
        }
    }

    componentWillUnmount=()=> {
        const { Backuplist } = this.state;
        this.setState({ videoList: Backuplist, selected:null })
    }

    // renderFilter = () => {
    //     const { filters, selected } = this.state;
    //     return filters.map((item, index) => {
    //         return (<span>
    //             <span className="color--grey">{index == filters.length - 1 && " and"}</span>
    //             <span className={"color--grey filter--hover "+ (selected==item&&" selected--filter")} onClick={() => this.filterList(item)}> {item}</span>
    //             <span className="color--grey">{index < filters.length - 2 && ","}</span>
    //             <span className="color--grey">{index == filters.length - 1 && "."}</span>
    //         </span>
    //         )
    //     })
    // }

    render() {
        const { videoList } = this.state;
        return (
            <div className="width--100">
                <Header />
                <Container fluid className="backgroundColor--black color--white">
                    <Row>
                        <Col className="no-padding">
                            <p className="font-family--clotherRegular Work--title font-size__700 ">Work</p>
                            <p className="font-family--clotherRegular Work--filter font-size__26 ">A curated selection of our projects including some award-winning work
                            </p>
                        </Col>
                    </Row>
                </Container>
                <VideoItem videoList={videoList} />
                <Footer/>
            </div>
        );
    }
}


import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Header from '../../components/header';
import Footer from '../../components/footer';
import VideoItem from '../../components/videoItem';
import "./style.css";
import seeAll from './../../assets/images/Buttons/allwork.svg';
import { Link } from 'react-router-dom';
import firebase from './../../services/firebase'
import RandomVideos from '../../components/randomVideos.js';
import ListVideo from '../../services/videoList';
import icon1 from "../../assets/images/Servicess/Icon1_Strategy.png"
import icon2 from "../../assets/images/Servicess/Icon2_BrandDesign.png"
import icon3 from "../../assets/images/Servicess/Icon3_Environment.png"
import CommonButton from '../../components/commonbutton/commonbutton';

export default class index extends Component {
    state = {

        data: [
            {
                img: icon1,
                title: "Strategy",
                bold: "Brand Research, Brand Strategy, Tone & Voice, Naming Creation, Logo Design, Identity System, Branding Guidelines.",
                points: ["Brand Strategy", "Experience", "Naming", "Verbal Identity"]
            },
            {
                img: icon2,
                title: "Brand Design",
                bold: "Audit, Research, Site Architecture, Web Design, Web Development, App Design.",
                points: ["Visual Identities", "Brand Guidelines", "Web & Mobile", "Packaging", "Print Collateral", "Editorial", "Art Direction", "Illustration", "Motion Graphics", " Social Media Graphics"]
            },
            {
                img: icon3,
                title: "Environment",
                bold: "Audit, Research, Site Architecture, Web Design, Web Development, App Design.",
                points: ["Signage & Wayfinding", "Environmental Graphics", "Interior Design", "Public Display Signage"]
            },
        ],
        videoList: ListVideo
    }

    componentDidMount = async () => {
        firebase.analytics().logEvent("Services Page Viewed")
        let videoList = ListVideo
        let random = []
        const rndInt = Math.floor(Math.random() * (4 - 0 + 1)) + 0;
        let rndInt2 = Math.floor(Math.random() * (4 - 0 + 1)) + 0;
        if (rndInt2 == rndInt) {
            if (rndInt2 == 0) {
                rndInt2 = rndInt2 + 1
            } else {
                rndInt2 = rndInt2 - 1
            }
        }
        random.push(videoList[rndInt2])
        random.push(videoList[rndInt])
        this.setState({ videoList: random })
    }

    handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    renderPoints = (array) => {
        return array.map((item) => {
            return <div className="width--100 content-flex">
                <div className="Services--Item-points">
                    <span className="font-family--clotherRegular line-height__18 services-subText font-size__30 p--paddingBottom-4">{item}
                    </span>
                </div>
            </div>

        })
    }

    renderData = () => {
        const { data } = this.state;
        return data.map((item, index) => {
            // return <Col xs={12} md={(index+1)%3==0?4:3} style={{paddingBottom:"10%",paddingRight:"5%"}}>
            return <Col xs={12} md={4} className="paddingLeft-custom">
                <img src={item.img} alt="icons" style={{width: "115px",height: "115px"}}/>
                <p className="font-family--clotherRegular  font-size__51 Services--Item-title mt-4">{item.title}</p>
                {/* <p style={{fontSize:"30px"}} className="font-family--clotherRegular line-height__18 services-subText font-size__30 p--paddingBottom-4">{item.bold}</p> */}
                {
                    this.renderPoints(item.points)
                }
            </Col>
        })
    }

    handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    render() {
        const { videoList } = this.state;

        return (
            <div className="width--100 backgroundColor--black">
                <Header />
                <Container fluid className="color--white">
                    <Row>
                        <Col className="no-padding">
                            <p className="font-family--clotherRegular services--title font-size__700 ">Services</p>
                            <p className="font-family--clotherRegular services--text font-size__30  p--paddingBottom-4">Our clients’ ideas are transformed by Tgda’s holistic approach into original and powerful results that position the brand for success</p>
                        </Col>
                    </Row>
                    <Row className="services-container"  >
                        {
                            this.renderData()
                        }
                    </Row>
                    <Row>
                        <div className='services--contact--button' onClick={() => this.handleClick()}>
                            <CommonButton value={{ bcolor: "white", arrow: "white", text: "Contact us", margin: "6%",link:"/contact" }} />
                            {/* <button className='font-family--clotherRegular font-weight__bold Home--button'>Contact us</button> */}
                        </div>
                    </Row>

                </Container>

                <RandomVideos />
                <Container fluid className="backgroundColor--black color--white">
                    <Row className="padding--5">
                        <Col className="see--all">
                            <div>
                                <CommonButton onClick={() => this.handleClick()} value={{ bcolor: "white", arrow: "white", text: "See all Work", margin: "0%", link: "/work" }} />
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </div>
        );
    }
}

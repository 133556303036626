import React, { Component } from 'react'
import { Grid } from "@material-ui/core";
import { Col, Container, Image, Row } from "react-bootstrap";
import SquareButton from './SquareButton.js';
import seeAll from './../../assets/images/Buttons/sellallvideos.svg';
import baedimg from './../../assets/images/Buttons/baed.svg';
import Navigator from './navigator.js';
import Based from './based';
import './style.css';
import Header from '../header';
import Footer from '../footer';
import firebase from './../../services/firebase';
import RandomVideos from '../randomVideos.js/index.js';
import award from "../../assets/images/aspera/IDA 22-Bronze.jpeg"
import { Link } from 'react-router-dom';
import CommonButton from '../commonbutton/commonbutton.js';
let image_1 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FPerAspera%2F1a.jpg?alt=media')
let image_2 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FPerAspera%2F2a.jpg?alt=media')
let image_3 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FPerAspera%2F3a.jpg?alt=media')
let image_4 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FPerAspera%2F4a.jpg?alt=media')
let image_5 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FPerAspera%2F5a.jpg?alt=media')
let image_6 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FPerAspera%2F6a.jpg?alt=media')
let image_7 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FPerAspera%2F7a.jpg?alt=media')
let image_8 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FPerAspera%2F8a.jpg?alt=media')
let image_9 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FPerAspera%2F9a.jpg?alt=media')
let image_10 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FPerAspera%2F10a.jpg?alt=media')
let image_11 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FPerAspera%2F11a.jpg?alt=media')
let image_12 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FPerAspera%2F12a.jpg?alt=media')
let image_13 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FPerAspera%2F13a.jpg?alt=media')
let image_14 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FPerAspera%2F14a.jpg?alt=media')
let image_15 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FPerAspera%2F15a.jpg?alt=media')
let image_16 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FPerAspera%2F16a.png?alt=media')
let image_17 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FPerAspera%2F17a.png?alt=media')
let image_18 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FPerAspera%2F18a.png?alt=media')
let image_19 = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FPerAspera%2F19a.gif?alt=media')
let banner = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/images%2FPerAspera%2F5_Banner.mp4?alt=media');
let cover = ('https://firebasestorage.googleapis.com/v0/b/tardigrada-website.appspot.com/o/videos%2FAspera%2Fcover.mp4?alt=media');


export default class Aspera extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drawerState: "null",
            redDotRight: 2
        };
    }

    componentDidMount() {
        var url_string = window.location.href
        var url = new URL(url_string);
        var filter = url.searchParams.get("filter");
        firebase.analytics().logEvent("BAED Page Viewed")
        if (filter) {
            let refactor = filter.split("%20").join(" ")
            this.setState({ currentFilter: refactor });
        }
        window.addEventListener('scroll', this.moveDot)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.moveDot)
    }

    moveDot = () => {
        const divScroll = document.getElementById("redDot").getBoundingClientRect()
        const height = Math.round(divScroll.bottom) - document.documentElement.clientHeight
        const scale = (((((height/divScroll.height)*100)*0.6)/100-0.4)+0.1)* -300
        if(scale>=2){
            this.setState({redDotRight: scale})
        } else {
            this.setState({redDotRight: 2})
        }
    };

    handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    render() {
        const { drawerState, redDotRight } = this.state;
        return (
            <div className="Template2--template2 Template backgroundColor--black aspera">
                <Header />
                <div className='cover--image backgroundColor--black'>
                    <video className="backgroundColor--black" playsInline preload="auto"
                        src={cover}
                        width="100%" loop={true} autoPlay={true} muted={true} type="video/mp4" />
                </div>
                <Grid style={{position:"relative"}}>
                    <p className="hero--Template1 color--white clother--regular font-size__110" style={{marginBottom:'3%', paddingTop:'10%', marginLeft:'6%', lineHeight:1.2}}>Per Aspera Ad Astra</p>
                    <Grid container style={{marginBottom:'10%'}}>
                        <Grid xs={5} style={{marginRight:'7%'}}>
                            <p className="color--white clother--regular font-size__30" style={{margin:'5% 5% 20% 7vw', lineHeight:1.6}}>Art Direction<br/>Packaging<br/></p>
                        </Grid>
                        <Grid xs={6}>
                            <p className="color--white font-size__16 line-height__20 clother--regular" style={{ padding: '5% 27% 0% 7%' }}>The title Per Aspera Ad Astra translates ‘Through Hardships to the Stars’. The design pays tribute to the recipient’s hard work and accomplishments; inspired from space traveling, multi-planetary life and the future.<br /><br />The primary purpose of the design is to house three genuine and rare gifts including two books: De La Terra a La Lune by Jules Verne and Apollo by Kollsman Instrument Corporation; and a golden steel Moroccan Astrolabe. The materials used for construction are aluminum, brass and magnets wrapped in matte vinyl&nbsp;prints.</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid style={{ position: "relative", backgroundColor: "rgb(33,34,35)" }}>
                    <div className='award--box'>
                        <div className='award--imagebox--1'>
                            <p className="color--white clother--regular font-size__30">Awards & <br />Recognitions</p>
                        </div>
                        <div className='award--imagebox--2'>
                            <div className='award--image--content'>
                                <img src={award} alt="award" className='award--image' />

                            </div>

                        </div>
                    </div>
                </Grid>
                <Grid container style={{ backgroundColor: `rgb(0, 0, 0)` }}>
                    <Grid item xs={12} className="centered--content__5" style={{ paddingBottom: "0%" }}>
                        <Image src={image_16} width="100%" />
                    </Grid>
                    <Grid container xs={12}>
                        <Grid xs={5} style={{ marginRight: '7%' }}></Grid>
                        <Grid xs={6}>
                            <p className="no--mobile color--white font-size__16 line-height__20 clother--regular" style={{ padding: '5% 20% 0% 7%' }}>
                                The mark was designed to convey a futuristic feeling. The graphic concept of the Package explores geometry and lines as the source of endless possibilities in the universe and the circle appears as the main shape representing the planets and the solar system. The solid red circle represents the planet Mars.
                                <br /><br /></p>
                                <p className="mobile--view color--white font-size__16 line-height__20 clother--regular" style={{ padding: '5% 20% 0% 7%' }}>
                                The mark was designed to convey a futuristic feeling. The graphic concept of the Package explores geometry and lines as the source of endless possibilities in the universe and the circle appears as the main shape representing the planets and the solar system. The solid red circle represents the planet Mars.
                                <br /><br /></p>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} id="redDot">
                        <Grid xs={11} style={{ marginTop: '6%', marginBottom: '7%' }}>
                            <Image src={image_17} width="100%" />
                        </Grid>
                        <Grid xs={1} className="aspera--redDot" style={{ right: `${redDotRight}%` }}>
                            <Image src={image_18} width="100%" />
                        </Grid>
                    </Grid>
                </Grid>
                
                <Grid container>
                    <Grid item xs={12}>
                        <Grid item xs={6} style={{ marginLeft: '7%', marginRight: '7%', paddingTop: '15%', paddingBottom: '2%',paddingRight:"7%" }}>
                            <p className="no--mobile color--white font-size__16 line-height__20 clother--regular">
                                The spine of the main box opens to reveal the Astrolabe, a classical multi-utility instrument used in astronomical measurements, for determining latitude for navigation and to identify stars or planets. <br /><br />The Astrolabe is accompanied by a set of iconographic cards designed by Tardigrada Design Studio taking inspiration from the Book of Fixed Stars by Abd al-Rahman al-Sufi.
                            </p>
                            <p className="mobile--view color--white font-size__16 line-height__20 clother--regular">
                                The spine of the main box opens to reveal the Astrolabe, a classical multi-utility instrument used in astronomical measurements, for determining latitude for navigation and to identify stars or planets. <br /><br />The Astrolabe is accompanied by a set of iconographic cards designed by Tardigrada Design Studio taking inspiration from the Book of Fixed Stars by Abd al-Rahman al-Sufi.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <div className="centered--content__5" style={{ paddingTop: '1%' }}>
                    <Grid container xs={12} spacing={2}>
                        <Grid item xs={6}>
                            <Image src={image_2} width="100%" />
                        </Grid>
                        <Grid item xs={6}>
                            <Image src={image_3} width="100%" />
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '5%' }}>
                            <Image src={image_4} width="100%" />
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '5%' }}>
                            <Image src={image_5} width="100%" />
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '5%' }}>
                            <Image src={image_19} width="100%" />
                        </Grid>
                    </Grid>
                </div>
                <div className="aspera--whiteCircle"></div>
                <div className="centered--content__5" style={{ paddingTop: '1%', paddingBottom: '8%' }}>
                    <Grid container xs={12} spacing={2}>

                        <Grid item xs={6}>
                            <Image src={image_6} width="100%" />
                        </Grid>
                        <Grid item xs={6}>
                            <p className="no--mobile aspera--MarginText color--white font-size__16 line-height__20 clother--regular">
                                The two slipcases with brass sides contain the books inside and are engraved with their respective titles. They slide out from the package from a red dot that symbolically represents the planet Mars.
                            </p>
                            <p className="mobile--view aspera--MarginText color--white font-size__16 line-height__20 clother--regular">
                                The two slipcases with brass sides contain the books inside and are engraved with their respective titles. They slide out from the package from a red dot that symbolically represents the planet Mars.
                            </p>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '5%' }}>
                            <Image src={image_7} width="100%" />
                        </Grid>
                    </Grid>
                </div>
                <Grid container style={{ backgroundColor: `rgb(13, 9, 12)` }}>
                    <Image src={image_8} width="100%" />
                    <Image src={image_12} width="100%" />
                </Grid>
                <div className="centered--content__5" style={{ paddingTop: '3%', paddingBottom: '8%' }}>
                    <Grid container xs={12} spacing={2}>
                        <Grid item xs={12} style={{ marginTop: '5%' }}>
                            <Image src={image_13} width="100%" />
                        </Grid>
                        <Grid item xs={6} style={{ marginTop: '5%', paddingBottom: '8%' }}>
                            <p className="no--mobile color--white font-size__16 line-height__20 clother--regular">
                                When both boxes are faced together they create a parabolic curve representing a spaceship piercing through the atmosphere against&nbsp;gravity.<br /><br />The form of the curve is inspired by the shape of a sine wave and pays tribute to the inventor of alternative current (AC) motor. The sine wave is a mathematical curve that occurs often in both pure and applied mathematics, as well as physics, engineering, signal processing and many other&nbsp;fields.
                            </p>
                            <p className="mobile--view color--white font-size__16 line-height__20 clother--regular">
                                When both boxes are faced together they create a parabolic curve representing a spaceship piercing through the atmosphere against&nbsp;gravity.<br /><br />The form of the curve is inspired by the shape of a sine wave and pays tribute to the inventor of alternative current (AC) motor. The sine wave is a mathematical curve that occurs often in both pure and applied mathematics, as well as physics, engineering, signal processing and many other&nbsp;fields.
                            </p>
                        </Grid>
                        <Grid item xs={6}>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '5%' }}>
                            <Image src={image_10} width="100%" />
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '5%' }}>
                            <Image src={image_14} width="100%" />
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '5%' }}>
                            <Image src={image_15} width="100%" />
                        </Grid>
                        <Grid item xs={6} style={{ marginTop: '5%', paddingBottom: '8%' }}>
                            <p className="no--mobile color--white font-size__16 line-height__20 clother--regular">
                                The books live in a red base illustration and are held in place by a<br /> black circular magnet.
                            </p>
                            <p className="mobile--view color--white font-size__16 line-height__20 clother--regular">
                                The books live in a red base illustration and are held in place by a black circular magnet.
                            </p>
                        </Grid>
                        <Grid item xs={6}>
                        </Grid>
                    </Grid>
                </div>
                {/* <Navigator screen={2} prev="baed" next="snaptotes"/> */}
                <RandomVideos exclude={4}/>
                <Container fluid className="backgroundColor--black color--white">
                    <Row className="padding--5">
                        <Col className="see--all">
                            <div onClick={() => this.handleClick()}>
                                <CommonButton value={{ bcolor: "white", arrow: "white", text: "More Work", margin: "0%", link: "/work" }} />
                            </div>
                        </Col>
                    </Row>
                </Container>
                {/* <Grid container>
                    <Grid item xs={12}>
                        <Based/>
                    </Grid>
                </Grid> */}
                <Footer />
            </div>
        )
    }
}
